const SET_SIZES_FILTER = 'SET_SIZES_FILTER';

const nextStateByType = {
  [SET_SIZES_FILTER]: (state, payload) => state.map((val, index) => index === payload.index ? payload.checked : val )
};

const reducer = (state = [false, false, false], action) => {
  const nextState = nextStateByType[action.type] || (state => state);
  return nextState(state, action.payload);
};

const setCheckedSizeAt = ({checked, index}) => ({
  type: SET_SIZES_FILTER,
  payload: {checked, index}
});

export const operations = {
  setCheckedSizeAt
};

export const selectors = {
  sizesFilter: state => state.foams.sizesFilter
}

export default reducer;