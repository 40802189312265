import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';
import { selectors as foamsPageSelectors } from '../foams-page/index';

/* ACCESSORIES */
const getAccessoriesInCartIds = state => state.cart.accessories;

/* FOAMS */
const getFoamIdsByDrawer = state => state.cart.foams;

const getFoamsInCartIds = createSelector(
  [getFoamIdsByDrawer],
  foams => foams.reduce((acc, val) => (acc.push(...val), acc), [])
);

const getCurrentDrawerFoamIds = createSelector(
  [getFoamIdsByDrawer, foamsPageSelectors.getSelectedDrawer],
  (foams, drawer) => foams[drawer - 1] || []
);

const makeGetDrawerFoamIds = createSelector(
  getFoamIdsByDrawer,
  foams => memoize(
    drawer => foams[drawer - 1] || []
  )
);

/* HUB */
const getHubId = state => state.cart.hub.model;
const getCartDrawers = state => state.cart.hub.drawers;
const getColor = state => state.cart.hub.color;


/* CART */
const getShouldShowSubtotal = createSelector(
  [getHubId, getColor, getCartDrawers],
  (hubId, color, drawers) => hubId && drawers && color
);

const getConfiguredCart = (state) => {
  return state.cart;
}

export default {
  getAccessoriesInCartIds,

  getCurrentDrawerFoamIds,
  getFoamIdsByDrawer,
  getFoamsInCartIds,
  makeGetDrawerFoamIds,

  getHubId,
  getCartDrawers,
  getColor,

  getShouldShowSubtotal,
  getConfiguredCart
};
