import React, { useCallback, memo } from 'react';

import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';

import classNames from 'classnames/bind';

import { imageTypes } from '../Image';
import Loader from '../Loader';
import ProductData from '../ProductData';
import { AccessoryProductImage, FoamProductImage } from '../ProductImage';
import { SnaPropTypes } from '../../propTypes';
import { AddAccessoryToCart, AddFoamToCart } from '../buttons/AddToCart';
import CloseButton from '../buttons/Close';
import { selectors, operations } from '../../../redux';
import {
  useQueryAccessory,
  useQueryFoam
} from '../../../graphql/hooks/useQueryProduct';

import styles from './styles';

let ProductPage = ({
  isOpened,
  productId,
  ProductImage,
  AddToCart,
  useQueryProduct
}) => {
  const { data: product, loading } = useQueryProduct(productId);
  const dispatch = useDispatch();
  const handleClick = useCallback(
    () => {
      dispatch(operations.productPage.closeProductPage());
    },
    [dispatch]
  );
  const cx = classNames.bind(styles);
  return (
    <div
      className={cx({
        ProductPage: true,
        isOpened
      })}
    >
      <CloseButton
        className={styles.ProductPage__close}
        onClick={handleClick}
      />
      {productId
        ? loading
          ? <Loader />
          : <React.Fragment>
              <ProductData
                {...{
                  product,
                  ProductImage,
                  classNames: ['u-scrollbars', 'u-scrollbars--y'],
                  cssmodule: styles,
                  imageType: imageTypes.PRODUCT_GALLERY
                }}
              />
              <div className={styles.ProductPage__buttonContainer}>
                <AddToCart
                  className={styles.ProductPage__button}
                  product={product}
                />
              </div>
            </React.Fragment>
        : null}
    </div>
  );
};
ProductPage = memo(ProductPage);
ProductPage.propTypes = {
  AddToCart: PropTypes.elementType,
  product: SnaPropTypes.product,
  ProductImage: PropTypes.elementType
};

const AccessoryPage = () => {
  const productId = useSelector(selectors.productPage.getProductId);
  const isOpened = useSelector(selectors.productPage.isAccessory);
  const props = {
    AddToCart: AddAccessoryToCart,
    isOpened,
    productId,
    ProductImage: AccessoryProductImage,
    useQueryProduct: useQueryAccessory
  };
  return <ProductPage {...props} />;
};

const FoamPage = () => {
  const productId = useSelector(selectors.productPage.getProductId);
  const isOpened = useSelector(selectors.productPage.isFoam);
  const props = {
    AddToCart: AddFoamToCart,
    isOpened,
    productId,
    ProductImage: FoamProductImage,
    useQueryProduct: useQueryFoam
  };
  return <ProductPage {...props} />;
};

export { AccessoryPage, FoamPage };
