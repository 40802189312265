import React from 'react';

import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import classNames from 'classnames/bind';

import { SnaPropTypes } from '../../../../propTypes';

import styles from './styles.css';
import SecondaryButton from '../../SecondaryButton';

const AddToCart = ({
  className,
  clickHandler,
  disabled,
  isInCart,
  forceAddMessage = false,
  messages,
  product,
}) => {
  const message = !forceAddMessage && isInCart ? messages.remove : messages.add;
  const cx = classNames.bind(styles);
  return (
    <SecondaryButton
      buttonClassName={cx(className, {
        disabledAndHidden: disabled
      })}
      onClick={clickHandler}
      disabled={disabled}
    >
      <FormattedMessage {...message} values={{ productName: product.name }} />
    </SecondaryButton>
  );
};

AddToCart.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isInCart: PropTypes.bool,
  forceAddMessage: PropTypes.bool,
  messages: PropTypes.shape({
    add: PropTypes.object.isRequired,
    remove: PropTypes.object.isRequired
  }).isRequired,
  product: SnaPropTypes.product.isRequired,
};

export default AddToCart;
