import { defineMessages } from "react-intl";

export default defineMessages({
  addAccessory: {
    id: "add accessory",
    defaultMessage: "add accessory"
  },
  addAccessoryX: {
    id: "add accessory X",
    defaultMessage: "add \"{productName}\""
  },
  addFoam: {
    id: "add foam to the drawer",
    defaultMessage: "add to the drawer"
  },
  addFoamX: {
    id: "add foam X to the drawer",
    defaultMessage: "add \"{productName}\" to the drawer"
  },
  moveFoamToDrawer: {
    id: "move foam to the drawer",
    defaultMessage: "move to this drawer"
  },
  moveFoamToDrawerX: {
    id: "move foam to drawer X",
    defaultMessage: "move to drawer {drawerNumber}"
  },
  moveFoamXToDrawer: {
    id: "move foam X to the drawer",
    defaultMessage: "move \"{productName}\" to this drawer"
  },
  moveFoamXToDrawerX: {
    id: "move foam X to drawer X",
    defaultMessage: "move \"{productName}\" to drawer {drawerNumber}"
  },
  removeAccessory: {
    id: "remove accessory",
    defaultMessage: "remove accessory"
  },
  removeAccessoryX: {
    id: "remove accessory X",
    defaultMessage: "remove \"{productName}\""
  },
  removeFoam: {
    id: "remove foam from the drawer",
    defaultMessage: "remove from the drawer"
  },
  removeFoamX: {
    id: "remove foam X from the drawer",
    defaultMessage: "remove \"{productName}\" from the drawer"
  }
});