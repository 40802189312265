import React from 'react';

import { uid } from 'react-uid';

import { Field } from 'react-final-form';

const Radio = ({
  cssmodule = {
    radio: 'radio',
    radio__input: 'radio__input',
    radio__label: 'radio__label'
  },
  label,
  name,
  onChange,
  value,
  id
}) => {
  return (
    <div className={cssmodule.radio}>
      <Field
        className={cssmodule.radio__input}
        component="input"
        id={id}
        name={name}
        onChange={() => onChange({ name, value })}
        type="radio"
        value={value}
      />

      <label className={cssmodule.radio__label} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

const RadioArray = ({
  cssmodule,
  legend,
  name,
  onChange,
  radios = [],
  ...attr
}) => {
  const {
    radioFieldset = 'radioFieldset',
    radioFieldset__legend = 'radioFieldset__legend',
    radioFieldset__container = 'radioFieldset__container'
  } =
    cssmodule || {};
  const commonProps = {
    cssmodule,
    name,
    onChange
  };
  return (
    <fieldset className={radioFieldset} {...attr}>
      {legend
        ? <legend className={radioFieldset__legend}>
            {legend}
          </legend>
        : null}
      <div className={radioFieldset__container}>
        {radios.map(({ label, value }, i) =>
          <Radio
            id={uid(name, i + 1)}
            key={i}
            {...commonProps}
            label={label}
            value={value}
          />
        )}
      </div>
    </fieldset>
  );
};

export { Radio, RadioArray };
