import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.css';
import { SnaPropTypes } from '../../../propTypes';

export const SecondaryButton = ({
  buttonClassName,
  children,
  componentClassName,
  disabled,
  href = false,
  onClick = () => {},
  target,
  type = 'button'
}) => {
  const isLink = href !== false;
  const isButton = !isLink;

  const touchableProps = {
    className: classNames(componentClassName, styles.ButtonTouchableArea),
    disabled,
    onClick,
    ...(isLink && {
      href,
      target
    }),
    ...(isButton && {
      type
    })
  };
  const visibleProps = {
    className: classNames(buttonClassName, styles.SecondaryButton)
  };

  return isLink
    ? <a {...touchableProps}>
        <span {...visibleProps}>
          {children}
        </span>
      </a>
    : <button {...touchableProps}>
        <span {...visibleProps}>
          {children}
        </span>
      </button>;
};

SecondaryButton.propTypes = {
  buttonClassName: SnaPropTypes.className,
  componentClassName: SnaPropTypes.className,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.string,
  type: PropTypes.string
};

export const SecondaryGhostButton = props => {
  let { buttonClassName } = props;
  buttonClassName = classNames(buttonClassName, styles.SecondaryGhostButton);
  return SecondaryButton({
    ...props,
    buttonClassName
  });
};

SecondaryGhostButton.propTypes = {
  ...SecondaryButton.propTypes
};

export default SecondaryButton;
