import types from './types';

const setConfigHash = configHash => ({
  type: types.SET_CONFIG_HASH,
  payload: {
    configHash
  }
});

export default {
  setConfigHash,
};
