import React, { useCallback, useRef } from 'react';

import { useDrag } from 'react-dnd';

import { useDispatch } from 'react-redux';

import classnames from 'classnames/bind';

import { imageTypes } from '../Image';
import ProductData from '../ProductData';
import { AccessoryProductImage, FoamProductImage } from '../ProductImage';
import { SnaPropTypes } from '../../propTypes';
import {
  AddAccessoryToCartMini,
  AddFoamToCartMini
} from '../buttons/AddToCart';
import config from '../../../config';
import { operations } from '../../../redux';
import { useIsAccessoryInCart } from '../../hooks/cart';
import { useIsFoamInCart } from '../../hooks/cart';

import styles from './styles.css';

const useFoamDrag = (foam, interactive, isInCart) => {
  const [{ draggingId, isDragging }, drag] = useDrag({
    item: { type: config.draggableItemTypes.foamFromList, id: foam.id, foam },
    collect: monitor => ({
      draggingId: monitor.getItem() && monitor.getItem().id,
      isDragging: monitor.isDragging()
    })
  });
  const notDraggable = useRef(null);
  return {
    draggingId,
    isDragging,
    drag: isInCart && !interactive ? notDraggable : drag
  };
};
const useOpenProductPage = product => {
  const dispatch = useDispatch();

  const openProductPage = useCallback(
    () => dispatch(operations.productPage.openProductPage(product)),
    [dispatch, product]
  );

  return openProductPage;
};

const Product = ({
  product,
  ProductImage,
  useIsInCart,
  useProductDrag = () => ({ drag: null }),
  AddToCartMini,
  interactive = false
}) => {
  const cx = classnames.bind(styles);
  const isInCart = useIsInCart(product);
  const isDraggable = !isInCart;
  const { drag } = useProductDrag(product, interactive, isInCart);
  const openProductPage = useOpenProductPage(product);
  return (
    product &&
    <div ref={isDraggable ? drag : null} className={cx({ product: true, interactive, isInCart })}>
      <button
        className={styles.product__viewProductPage}
        onClick={openProductPage}
      >
        <ProductData
          {...{
            product,
            ProductImage,
            cssmodule: styles,
            imageType: imageTypes.PRODUCT_LIST_ITEM
          }}
        />
      </button>
      <AddToCartMini
        className={styles.product__addToCart}
        product={product}
        disabled={!interactive}
      />
    </div>
  );
};

const Accessory = props =>
  Product({
    ...props,
    ProductImage: AccessoryProductImage,
    useIsInCart: useIsAccessoryInCart,
    AddToCartMini: AddAccessoryToCartMini
  });

Accessory.propTypes = {
  product: SnaPropTypes.accessory.isRequired
};

const Foam = props =>
  Product({
    ...props,
    ProductImage: FoamProductImage,
    useIsInCart: useIsFoamInCart,
    useProductDrag: useFoamDrag,
    AddToCartMini: AddFoamToCartMini
  });

Foam.propTypes = {
  product: SnaPropTypes.foam.isRequired
};

export { Accessory, Foam };
