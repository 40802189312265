import classNames from 'classnames';

import Image, { imageTypes } from '../Image';
import { SnaPropTypes } from '../../propTypes';
import PropTypes from 'prop-types';
import styles from './styles.css';

const FoamImage = ({ className, foam, lazy }) => {
  const { name, previewImage, size } = foam;
  return Image({
    alt: name,
    className: classNames(
      className,
      styles.FoamImage,
      styles[`hasSize${size.value}`]
    ),
    lazy,
    imageType: imageTypes[`FOAM_PREVIEW_SIZE_${size.value}`],
    src: previewImage
  });
};

FoamImage.propTypes = {
  className: SnaPropTypes.className,
  foam: SnaPropTypes.foam.isRequired,
  lazy: PropTypes.bool
};

export default FoamImage;
