import config from '../../../config';

import types from './types';

const openDrawersPopUp = (
  foamToAdd,
  targetDrawer,
  drawersPopUpType = config.drawersPopUpTypes.addTo
) => {
  const { id, name, sku, size } = foamToAdd;
  return {
    type: types.OPEN_DRAWERS_POP_UP,
    payload: {
      foam: {
        id,
        name,
        sku,
        size
      },
      drawer: targetDrawer,
      type: drawersPopUpType
    }
  };
};

const closeDrawersPopUp = () => ({
  type: types.CLOSE_DRAWERS_POP_UP
});

export default {
  openDrawersPopUp,
  closeDrawersPopUp
};
