import types from './types';

const initialState = {
  productId: null,
  isAccessory: false,
  isFoam: false
};

const nextStateByType = {
  [types.OPEN_PRODUCT_PAGE]: (state, action) => ({
    ...state,
    ...action.payload
  }),
  [types.CLOSE_PRODUCT_PAGE]: () => initialState
};

const reducer = (state = initialState, action) => {
  const nextState = nextStateByType[action.type] || (state => state);
  return nextState(state, action);
};

export default reducer;
