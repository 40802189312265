import useAppConfigState from './useAppConfigState';
import { useCallback } from 'react';

const isRelativeUrl = url => url.indexOf('http') !== 0;

const useImageUrlBase = url => {
  return useImageUrlBaseCallback()(url);
};

export const useImageUrlBaseCallback = () => {
  const appConfig = useAppConfigState();
  return useCallback(
    url => isRelativeUrl(url) ? `${appConfig.urlTo.imgs}/${url}` : url,
    [appConfig]
  );
};

export default useImageUrlBase;
