import React, { useCallback } from 'react';

import { FormattedMessage } from 'react-intl';

import { useSelector, useDispatch } from 'react-redux';

import config from '../../../../config';
import { selectors, operations } from '../../../../redux';
import DrawerName from '../../../components/DrawerName';
import PopUp from '../../../components/PopUp';
import { useDrawersWhereFoamFits, useDoesFoamFitInTrolley } from '../../../hooks/drawer';
import { AddFoamToCart } from '../../../components/buttons/AddToCart';

import messages from './messages';
import styles from './styles.css';

const DrawerListForFoam = ({ foam, closePopUp, drawer, movingFoams }) => {
  const drawers = useDrawersWhereFoamFits(foam);
  return (
    drawers &&
    <ul className={styles.DrawerListForFoam}>
      {drawers.filter(d => d.drawer !== drawer).map((d, i) =>
        <li
          className={styles.DrawerListForFoam__drawer}
          key={`drawer-pop-up-name-${i}`}
        >
          <DrawerName
            drawer={d.drawer}
            showFreeSpaceIcon={true}
            showFreeSpaceText={true}
          />
          <AddFoamToCart
            drawer={d.drawer}
            moveFromAnotherDrawer={movingFoams}
            onClick={closePopUp}
            product={foam}
          />
        </li>
      )}
    </ul>
  );
};

const DrawersPopUp = () => {
  const drawer = useSelector(selectors.foams.getPopUpTargetDrawer);
  const foam = useSelector(selectors.foams.getPopUpFoamData);
  const popUpType = useSelector(selectors.foams.getPopUpType);
  const movingFoams = popUpType === config.drawersPopUpTypes.moveTo;
  const dispatch = useDispatch();
  const doesFoamFitInTrolley = useDoesFoamFitInTrolley(foam);
  const handleClick = useCallback(
    () => {
      dispatch(operations.foams.closeDrawersPopUp());
    },
    [dispatch]
  );
  const messageChoose = movingFoams
    ? messages.moveFoamToAnotherDrawer
    : messages.addFoamToAnotherDrawer;
  return (
    <PopUp onClose={handleClick} isOpened={foam !== null}>
      {foam &&
        <React.Fragment>
          <p className={styles.DrawerListForFoam__warning}>
            <FormattedMessage
              {...messages.drawerHasNotEnoughFreeSpace}
              values={{ drawerNumber: drawer }}
            />
          </p>
          {doesFoamFitInTrolley
            ? <React.Fragment>
              <p>
                <FormattedMessage {...messageChoose} />
              </p>
              <DrawerListForFoam
                foam={foam}
                closePopUp={handleClick}
                movingFoams={movingFoams}
              />
            </React.Fragment>
            : <p>
              <FormattedMessage {...messages.yourTrolleyIsFull} />
            </p>
          }
        </React.Fragment>}
    </PopUp>
  );
};

DrawersPopUp.propTypes = {};

export default DrawersPopUp;
