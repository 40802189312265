import PropTypes from 'prop-types';
import { propType as colorPropType } from './color';

export const colorSelection = (color, drawers) => ({
    color: color,
    drawers: drawers
  });

export const propType = PropTypes.shape({
  color: colorPropType,
  drawers: PropTypes.number
});
