/* ACCESSORIES */
const ADD_ACCESSORY_TO_CART = 'ADD_ACCESSORY_TO_CART';
const REMOVE_ACCESSORY_FROM_CART = 'REMOVE_ACCESSORY_FROM_CART';
const SET_CART_ACCESSORIES = 'SET_CART_ACCESSORIES';
/* FOAMS */
const ADD_FOAM = 'ADD_FOAM';
const REMOVE_FOAM = 'REMOVE_FOAM';
const REORDER_FOAMS = 'REORDER_FOAMS';
const RESET_ALL_DRAWERS = 'RESET_ALL_DRAWERS';
const RESET_DRAWER = 'RESET_DRAWER';
const SELECT_DRAWERS_LIMIT = 'SELECT_DRAWERS_LIMIT';
/* HUB */
const SELECT_HUB = 'SELECT_HUB';
const SELECT_CHEST = 'SELECT_CHEST';
const SELECT_DRAWERS = 'SELECT_DRAWERS';
/* CART */
const SET_CART = 'SET_CART';

export default {
  ADD_ACCESSORY_TO_CART,
  REMOVE_ACCESSORY_FROM_CART,
  SET_CART_ACCESSORIES,
  
  ADD_FOAM,
  REMOVE_FOAM,
  REORDER_FOAMS,
  RESET_ALL_DRAWERS,
  RESET_DRAWER,
  SELECT_DRAWERS_LIMIT,
  
  SELECT_HUB,
  SELECT_CHEST,
  SELECT_DRAWERS,

  SET_CART,
};
