import types from './types';

const setConfigName = configName => ({
  type: types.SET_CONFIG_NAME,
  payload: {
    configName
  }
});

export default {
  setConfigName,
};
