export const productFieldsForListItem = `
  betms_image {
    big {
      url
    }
    small {
      url
    }
    original {
      url
    }
  }
  name
  betms_price
  sku
`;

// Removed technical details id since ApolloClient uses it to cache data
export const productFieldsForDetails = `
  ${productFieldsForListItem}
  details
  technical_details {
    icon_url
    label
    value
  }
`;