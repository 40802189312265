import actions from './actions';
import selectors from './selectors';
import { operations as globalOperations } from '../index';
import { selectors as globalSelectors } from '../index';

/* FOAMS */
const addFoamToDrawer = (foam, drawer) => (dispatch, getState) => {
  const foamsInDrawer = selectors.getFoamsInCartIds(getState());
  if (!foamsInDrawer.includes(foam.id)) {
    dispatch(actions.addFoamToDrawer(foam, drawer));
  }
};
const moveFoamToDrawer = (foam, drawer) => dispatch => {
  dispatch(actions.removeFoam(foam));
  dispatch(actions.addFoamToDrawer(foam, drawer));
};

/* HUB */
const selectDrawers = numDrawers => (dispatch, getState) => {
  dispatch(actions.selectDrawers(numDrawers));
  dispatch(actions.setDrawersLimit(numDrawers));

  const selectedDrawerInFoamsPage = globalSelectors.foams.getSelectedDrawer(
    getState()
  );
  if (selectedDrawerInFoamsPage > numDrawers) {
    dispatch(globalOperations.foams.resetSelectedDrawer());
  }
};

const selectHub = ({ hubId, color, accessoryIds }) => dispatch => {
  dispatch(actions.selectHub(hubId, color));
  dispatch(actions.setCartAccessoryIds(accessoryIds));
}

/* CART */
const setCart = ({ cart, configHash, name }) => dispatch => {
  if (cart !== null) {
    dispatch(globalOperations.configHash.setConfigHash(configHash));
    dispatch(globalOperations.configName.setConfigName(name));
    dispatch(actions.setCart(cart));
  }
};

export default {
  ...actions,
  addFoamToDrawer,
  moveFoamToDrawer,
  selectDrawers,
  setCart,
  selectHub
};
