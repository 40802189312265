import React, { useCallback } from 'react';

import classNames from 'classnames';

import PropTypes from 'prop-types';

import { SnaPropTypes } from '../../propTypes';
import CloseButton from '../buttons/Close';

import styles from './styles';

const PopUp = ({ className, children, isOpened = false, onClose }) => {
  const stopPropagation = useCallback(evt => {
    evt.stopPropagation();
  }, []);
  const closeHandler = useCallback(
    evt => {
      stopPropagation(evt);
      onClose();
    },
    [onClose, stopPropagation]
  );
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
    className={classNames(className, {
      [styles.PopUpOverlay]: true,
      [styles.isOpened]: isOpened
    })}
    onClick={closeHandler}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames(className, {
          [styles.PopUp]: true,
          [styles.isOpened]: isOpened
        })}
        onClick={stopPropagation}
      >
        <CloseButton className={styles.PopUp__close} onClick={closeHandler} />
        {children}
      </div>
    </div>
  );
};

PopUp.propTypes = {
  className: SnaPropTypes.className,
  isOpened: PropTypes.bool,
  onClose: PropTypes.func
};

export default PopUp;
