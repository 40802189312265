import { useEffect, useState } from 'react';

import useAppConfigState from './useAppConfigState';
import useMedia from './useMedia';

export const imageTypes = {
  FOAM_PREVIEW_SIZE_1: 'foamPreviewSize1',
  FOAM_PREVIEW_SIZE_2: 'foamPreviewSize2',
  FOAM_PREVIEW_SIZE_3: 'foamPreviewSize3',
  HUB_ICON: 'hubIcon',
  HUB_WITH_ACCESSORIES_PREVIEW: 'hubWithAccessoriesPreview',
  PRODUCT_GALLERY: 'productGallery',
  PRODUCT_LIST_ITEM: 'productListItem',
  TECHNICAL_ATTRIBUTE_ICON: 'technicalAttributeIcon',
  TROLLEY_PREVIEW: 'trolleyPreview'
};

export const useFoamPreviewImageSizes = foam =>
  useAppImageSizes(imageTypes[`FOAM_PREVIEW_SIZE_${foam.size.value}`]);

function useAppImageSizes(imageType) {
  const defaultSize = {
    mediaQuery: false,
    size: ['', '']
  };
  const appConfig = useAppConfigState();
  const [mobile = defaultSize, desktop = defaultSize] =
    (imageType && appConfig.imageSizes[imageType]) || [];
  const matchesMobile = useMedia(mobile.mediaQuery);
  const matchesDesktop = useMedia(desktop.mediaQuery);

  const [height, setHeight] = useState('');
  const [width, setWidth] = useState('');

  useEffect(
    () => {
      const [matchedWidth, matchedHeight] = matchesDesktop
        ? desktop.size
        : mobile.size;
      setWidth(matchedWidth);
      setHeight(matchedHeight);
    },
    [matchesMobile, matchesDesktop, desktop.size, mobile.size]
  );

  return {
    height,
    width
  };
}

export default useAppImageSizes;
