import { useMemo } from 'react';

import { useQueryTrolleyAll } from './useQueryTrolley';
import  { colorSelection } from '../../model/colorSelection';
import { useTrolleySelectedData } from '../../app/hooks/cart';

const useGetAvailableColorsForHub = (hubId = 'E77') => {
  const {data, loading, error} = useQueryTrolleyAll({ hubId });
  const selectedTrolleyData = useTrolleySelectedData();
  const colorArray = useMemo(
    () => {
      const isTrolleyColorAlreadyFound = (trolley, obj) =>
        Object.prototype.hasOwnProperty.call(obj, trolley.color.id);

      const thereAreNoDrawersSelected = selectedTrolleyData.drawers === null || selectedTrolleyData.drawers === undefined;
      const getNewDrawers = (previousDrawers, currentDrawers) => {
        if (thereAreNoDrawersSelected) {
          return undefined;
        }
        const selectedDrawersMatchLastReducedDrawers = selectedTrolleyData.drawers === previousDrawers;
        if (selectedDrawersMatchLastReducedDrawers) {
          return previousDrawers;
        }

        return currentDrawers > previousDrawers ? currentDrawers : previousDrawers;
      };

      const reduceTrolleyArrayToColorArray = (acc, current) => {
        const previousDrawers = isTrolleyColorAlreadyFound(current, acc) ? acc[current.color.id].drawers : 0;
        const newDrawers = getNewDrawers(previousDrawers, current.drawers);
        acc[current.color.id] = colorSelection(current.color, newDrawers);
        return acc;
      };
      return data.reduce(reduceTrolleyArrayToColorArray, {});
    },
    [data, selectedTrolleyData.drawers]
  );
  return {data: colorArray, loading, error };
};

export default useGetAvailableColorsForHub;
