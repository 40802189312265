import { applyMiddleware, compose, createStore } from 'redux';

import { createBrowserHistory } from 'history';

import thunk from 'redux-thunk';

import { persistStore, persistReducer } from 'redux-persist';

import storageSession from 'redux-persist/lib/storage/session';

import config from './config';
import reducer from './redux';

export const history = createBrowserHistory({ basename: config.baseUrl });

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['cart', 'configHash', 'configName']
};

export default function configureStore(preloadedState) {
  const composeEnhancer =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const persistedReducer = persistReducer(persistConfig, reducer(history));

  const store = createStore(
    persistedReducer, // root reducer with router state
    preloadedState,
    composeEnhancer(
      applyMiddleware(
        thunk,
        // ... other middlewares ...
      )
    )
  );
  const persistor = persistStore(store);
  return { store, persistor };
}
