import { defineMessages } from "react-intl";

export default defineMessages({
    searchNameOrReference: {
        id: "searchNameOrReference",
        defaultMessage: "Search Name or Reference"
    },
    allSectors: {
        id: "allSectors",
        defaultMessage: "All Sectors"
    },
    foamIndustries1: {
        id: "foamIndustries1",
        defaultMessage: "Industry"
    },
    foamIndustries2: {
        id: "foamIndustries2",
        defaultMessage: "Trades"
    },
    foamIndustries3: {
        id: "foamIndustries3",
        defaultMessage: "Automotive"
    },
    foamIndustries4: {
        id: "foamIndustries4",
        defaultMessage: "Green"
    },
    foamIndustries5: {
        id: "foamIndustries5",
        defaultMessage: "Industrial Cutting"
    }
});