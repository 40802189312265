import React from 'react';
import PopUp from '../../../components/PopUp';
import useConfigHash from '../../../hooks/useConfigHash';
import {CreateConfigForm, UpdateConfigForm} from '../Forms';

const SaveConfigPopUp = ({ isOpen, onClose }) => {
  const configHash = useConfigHash();
  return <PopUp onClose={onClose} isOpened={isOpen}>
    {configHash
      && <UpdateConfigForm onClose={onClose} />
      || <CreateConfigForm onClose={onClose} />}
  </PopUp>;
};

export default SaveConfigPopUp;