import types from './types';

const openProductPage = product => ({
  type: types.OPEN_PRODUCT_PAGE,
  payload: {
    productId: product.id,
    isAccessory: product.size === undefined,
    isFoam: product.size !== undefined
  }
});

const closeProductPage = () => ({
  type: types.CLOSE_PRODUCT_PAGE
});

export default {
  openProductPage,
  closeProductPage
};
