// import { connect } from 'react-redux';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from "react-redux";
import { selectors } from '../../redux';
import { languageFromStoreViewCode } from '../../utils/store-view';

export const createIntlProvider = (localeDef, messagesDef) => {
  const On4IntlProvider = ({ 
    children, 
    locale = localeDef, 
    messages = messagesDef }) => {
    return (
      <IntlProvider locale={locale} messages={messages}>
        { children }
      </IntlProvider>
    );
  }
  
  return On4IntlProvider;
}

export const createConnectedIntlProvider = () => ({ children }) => {
    const storeView = useSelector(selectors.storeView.getCode);
    const reactIntlLocale = languageFromStoreViewCode(storeView);
    const messages = useSelector(selectors.storeView.getMessages);
    
    return (
      <IntlProvider locale={reactIntlLocale} messages={messages}>
        { children }
      </IntlProvider>
    );
  }