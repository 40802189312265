const SET_FOAM_SEARCH_TEXT = 'SET_FOAM_SEARCH_TEXT';

const nextStateByType = {
  [SET_FOAM_SEARCH_TEXT]: (state, payload) => payload
};

const reducer = (state = "", action) => {
  const nextState = nextStateByType[action.type] || (state => state);
  return nextState(state, action.payload);
};

const setSearchText = text => ({
  type: SET_FOAM_SEARCH_TEXT,
  payload: text
});

export const operations = {
    setSearchText
};

export const selectors = {
  searchText: state => state.foams.searchText
}

export default reducer;
