import { useSelector } from 'react-redux';

import { queryHooks } from '../../graphql';
import { selectors } from '../../redux';
import { FOAM_SIZE_VALUES } from '../../model/foam';

export const useFoamSizesFilter = () => {
    const foamSizesFilter = useSelector(selectors.foams.sizesFilter);
    return FOAM_SIZE_VALUES.filter((val, index) => foamSizesFilter[index]);
};

export const useSearchText = () => {
  return useSelector(selectors.foams.searchText);
}

export const useIndustriesSelection = () => {
  return useSelector(selectors.foams.industriesFilter);
}

export const useFilteredFoams = () => {
  const searchText = useSearchText();
  const industry = useIndustriesSelection();
  const foamSizes = useFoamSizesFilter();
  return queryHooks.useGetFoamsQuery({searchText, industry, foamSizes});
}

export const useAvailableIndustries = () => {
  return queryHooks.useGetAvailableIndustriesQuery(); 
}
