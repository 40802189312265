import React from 'react';

import { injectIntl } from 'react-intl';

import { SnaPropTypes } from '../../../propTypes';
import Price from '../../../components/Price';
import { PriceWarningText } from '../../../components/PriceWarning';
import { useShouldShowPrice } from '../../../hooks/useShouldShowPrice';

import messages from './messages';
import styles from './styles.css';

const HubPrice = ({ intl, value }) => {
  const shouldShowPrice = useShouldShowPrice();
  return shouldShowPrice.data
    ? <React.Fragment>
        <dl className={styles.price}>
          <dt className={styles.price__label}>
            {intl.formatMessage(messages.priceFrom)}
          </dt>
          <dd className={styles.price__value}>
            <Price value={value} />
          </dd>
        </dl>
        <PriceWarningText cssModule={styles}/>
      </React.Fragment>
    : null;
};

HubPrice.propTypes = {
  value: SnaPropTypes.price.isRequired
};

export default injectIntl(HubPrice);
