import React from 'react';

import { injectIntl } from 'react-intl';

import classNames from 'classnames';

import { SnaPropTypes } from '../../propTypes';
import { useDrawerFreeSpace } from '../../hooks/drawer';

import messages from './messages';
import styles from './styles.css';

const getMessage = (freeSpace, isFull, intl) =>
  isFull
    ? intl.formatMessage(messages.full)
    : intl.formatMessage(messages.freeSpace, { space: `${freeSpace} / 3` });

export const DrawerCapacityText = injectIntl(({ className, cssModule = {}, drawer, intl }) => {
  const { freeSpace, isFull } = useDrawerFreeSpace(drawer);
  return (
    <span
      className={classNames(className, {
        [cssModule.DrawerCapacityText]: true,
        [cssModule.isFull]: isFull,
        [styles.DrawerCapacityText]: true,
        [styles.isFull]: isFull
      })}
    >
      {getMessage(freeSpace, isFull, intl)}
    </span>
  );
});

DrawerCapacityText.propTypes = {
  cssModule: SnaPropTypes.cssModule,
  drawer: SnaPropTypes.drawer.isRequired
};

export const DrawerCapacityIcon = injectIntl(({ drawer, intl }) => {
  const { freeSpace, isFull } = useDrawerFreeSpace(drawer);
  return (
    <span
      className={styles.DrawerCapacityIcon}
      data-free-space={freeSpace}
      title={getMessage(freeSpace, isFull, intl)}
    >
      <span className="visually-hidden">
        {getMessage(freeSpace, isFull, intl)}
      </span>
    </span>
  );
});

DrawerCapacityIcon.propTypes = {
  drawer: SnaPropTypes.drawer.isRequired
};
