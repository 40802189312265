import { propType as accessory } from "./accessory";
import { propType as color } from "./color";
import { propType as foam } from "./foam";
import { propType as price } from "./price";
import { propType as product } from "./product";
import { propType as trolley } from "./trolley";

export const ModelPropTypes = {
  accessory,
  color,
  foam,
  price,
  product,
  trolley
}
