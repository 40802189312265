import React from 'react';

import { injectIntl } from 'react-intl';

import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from 'react-accessible-accordion';

import { SnaPropTypes } from '../../propTypes';

import { messages } from './messages';
import styles from './styles.css';

export const ProductDetailsHeading = injectIntl(({ intl }) =>
  intl.formatMessage(messages.productDetails)
);

export const ProductDetailsContent = ({ details }) => {
  return (
    details &&
    <ul>
      {details.map((detail, index) =>
        <li key={index}>
          {detail}
        </li>
      )}
    </ul>
  );
};

export const ProductDetailsAccordionItem = ({ product }) => {
  return (
    product &&
    product.details &&
    <AccordionItem className={styles.AccordionItem}>
      <AccordionItemHeading>
        <AccordionItemButton className={styles.AccordionItem__heading}>
          <ProductDetailsHeading />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className={styles.AccordionItem__content}>
        <ProductDetailsContent details={product.details} />
      </AccordionItemPanel>
    </AccordionItem>
  );
};

ProductDetailsAccordionItem.propTypes = {
  product: SnaPropTypes.product
};
