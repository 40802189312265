import React from 'react';

import styles from './styles.css';
import classNames from 'classnames';

const Loader = ({ containerClassName }) => {
  return (
    <div className={classNames(containerClassName, styles.loaderContainer)}>
      <div className={styles.loader} />
    </div>
  );
};

export default Loader;
