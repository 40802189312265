import React from 'react';

import { Form } from 'react-final-form';

import { SnaPropTypes } from '../../propTypes';
import NextStepButton from '../buttons/NextStepButton';

const NextStepForm = ({ className, step }) => {
  const onSubmit = () => {};
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) =>
        <form onSubmit={handleSubmit} className={className}>
          <NextStepButton step={step} />
        </form>}
    />
  );
};

NextStepForm.propTypes = {
  className: SnaPropTypes.className,
  step: SnaPropTypes.step.isRequired
};

export default NextStepForm;
