import React from 'react';

const NavButton = ({ 
  cssmodule,
  iconAfter = '',
  iconBefore = '',
  onClick,
  text,
  type = 'button'
}) => (
  <button type={type} className={cssmodule.navButton} onClick={onClick}>
    {iconBefore}
    <span className={cssmodule.navButton__text}>{text}</span>
    {iconAfter}
  </button>
);

export default NavButton;
