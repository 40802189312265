import React from 'react';
import classNames from 'classnames';

import styles from './styles.css';
import SecondaryButton, { SecondaryGhostButton } from '../SecondaryButton';

export const PrimaryButton = props => {
  let { buttonClassName, children } = props;
  buttonClassName = classNames(buttonClassName, styles.PrimaryButton);
  return (
    <SecondaryButton
      {...{
        ...props,
        buttonClassName
      }}
    >
      {children}
    </SecondaryButton>
  );
};

export const PrimaryGhostButton = props => {
  let { buttonClassName, children } = props;
  buttonClassName = classNames(buttonClassName, styles.PrimaryGhostButton);
  return (
    <SecondaryGhostButton
      {...{
        ...props,
        buttonClassName
      }}
    >
      {children}
    </SecondaryGhostButton>
  );
};

PrimaryButton.propTypes = {
  ...SecondaryButton.propTypes
};

PrimaryGhostButton.propTypes = {
  ...SecondaryGhostButton.propTypes
};

export default PrimaryButton;
