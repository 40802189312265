import { useQuery } from '@apollo/react-hooks';

import { gql } from 'apollo-boost';
import useStoreViewCode from '../../app/hooks/useStoreViewCode';
import {useLocale} from '../../app/hooks/useLocale';
import config from '../../config';

const query = gql`
  {
    storeConfig {
      should_show_prices
      default_display_currency_code
    }
  }
`;

export const useGetShouldShowPricesQuery = () => {
  const storeViewCode = useStoreViewCode();
  const locale = useLocale();
  const { data, loading, error } = useQuery(query, {
    context: {
      headers: {
        Store: storeViewCode
      },
      uri: config.localizedGraphQlServerUrl(locale)
    }
  });
  const shouldShowPrices = (
    (data && data.storeConfig && data.storeConfig.should_show_prices) || false
  );
  return { data: shouldShowPrices, loading, error }
};

export const useGetCurrencyCodeQuery = () => {
  const storeViewCode = useStoreViewCode();
  const locale = useLocale();
  const { data, loading, error } = useQuery(query, {
    context: {
      headers: {
        Store: storeViewCode
      },
      uri:  config.localizedGraphQlServerUrl(locale)
    }
  });
  const currencyCode = data && data.storeConfig && data.storeConfig.default_display_currency_code;
  return { data: currencyCode, loading, error }
};