import React from 'react';

import { injectIntl, FormattedMessage } from 'react-intl';

import classnames from 'classnames';

import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from 'react-accessible-accordion';

import Image, { imageTypes } from '../Image';
import { SnaPropTypes } from '../../propTypes';

import messages from './messages';
import styles from './styles.css';

export const ProductTechnicalDetailsHeading = injectIntl(({ intl }) =>
  intl.formatMessage(messages.productTechnicalDetails)
);

export const ProductTechnicalDetailsContent = ({ product }) => {
  const { sku, technicalDetails } = product;
  return (
    technicalDetails &&
    <MobileTechnicalDetails sku={sku} technicalDetails={technicalDetails} />
  );
};

const MobileTechnicalDetails = ({ sku, technicalDetails }) => {
  return (
    <div className={styles.MobileTechnicalTableContainer}>
      <table>
        <thead>
          <tr>
            <th scope="row">
              <FormattedMessage {...messages.product} />
            </th>
            <td>
              {sku}
            </td>
          </tr>
        </thead>
        <tbody>
          {technicalDetails.map((attr, index) =>
            (<tr key={`technical-details-attribute-tr-${index}`} >
              <th scope="row">
                {attr.label}
              </th>
              <td>
                {attr.value}
              </td>
            </tr>))}
        </tbody>
      </table>
    </div>);
};

// eslint-disable-next-line no-unused-vars
const DesktopTechnicalDetails = ({ sku, technicalDetails }) => {
  return (
    <div className={styles.TechnicalTableContainer}>
      <table className={styles.TechnicalTableContainer__product}>
        <thead>
          <tr>
            <th>
              <FormattedMessage {...messages.product} />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {sku}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        className={classnames(
          styles.TechnicalTableContainer__attrsTableContainer,
          'u-scrollbars',
          'u-scrollbars--x'
        )}
      >
        <table className={styles.TechnicalTableContainer__attrsTable}>
          <thead>
            <tr>
              {technicalDetails.map((attr, index) =>
                <th key={`technical-details-attribute-th-${index}`} title={attr.label}>
                  {attr.iconUrl
                    ? <Image
                      alt={attr.label}
                      imageType={imageTypes.TECHNICAL_ATTRIBUTE_ICON}
                      src={attr.iconUrl}
                      title={attr.label}
                    />
                    : attr.label}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              {technicalDetails.map((attr, index) =>
                <td
                  data-th={attr.label}
                  key={`technical-details-attribute-td-${index}`}
                >
                  {attr.value}
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const ProductTechnicalDetailsAccordionItem = ({ product }) => {
  return (
    product &&
    <AccordionItem className={styles.AccordionItem}>
      <AccordionItemHeading>
        <AccordionItemButton className={styles.AccordionItem__heading}>
          <ProductTechnicalDetailsHeading />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className={styles.AccordionItem__content}>
        <ProductTechnicalDetailsContent product={product} />
      </AccordionItemPanel>
    </AccordionItem>
  );
};

ProductTechnicalDetailsAccordionItem.propTypes = {
  product: SnaPropTypes.product
};
