import React from 'react';
import { useUID } from 'react-uid';
import { Field } from 'react-final-form';

const Checkbox = ({
  cssmodule = { 
    checkbox: 'checkbox',
    checkbox__input: 'checkbox__input',
    checkbox__label: 'checkbox__label'
  },
  name,
  label,
  value,
  onChange,
  checked
}) => {
  const uid = useUID(); 

  return (
    <div className={cssmodule.checkbox}>
      <Field
        className={cssmodule.checkbox__input}
        component="input"
        id={uid}
        name={name}
        type="checkbox"
        value={value}
        onChange={(e) => {
          const isChecked = e.target.value === 'true';
          const newState = !isChecked;
          onChange({ name, checked: newState })
        }}
        checked={checked}
      />

      <label className={cssmodule.checkbox__label} htmlFor={uid}>
        {label}
      </label>
    </div>    
  );
}

export { Checkbox };

