import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import DrawerContent from '../DrawerContent';
import DrawerName from '../DrawerName';
import { PriceWarningText } from '../PriceWarning';
import { FoamList } from '../ProductList';
import { useFoamsInDrawer, useTrolleySelectedData } from '../../hooks/cart';

import styles from './styles.css';

const FoamListForDrawer = (
  drawer,
  interactive,
  showDrawerPreview,
  showFreeSpaceText,
  showPriceWarning
) => {
  const foamsInDrawer = useFoamsInDrawer(drawer);
  return (
    <div
      className={classNames({ [styles.hasDrawerPreview]: showDrawerPreview })}
      key={`foamlist-for-drawer-${drawer}`}
    >
      <FoamList
        key={drawer}
        products={foamsInDrawer}
        heading={
          <DrawerName
            drawer={drawer}
            showFreeSpaceText={showFreeSpaceText}
            showPriceWarning={showPriceWarning}
          />
        }
        interactive={interactive}
      />
      {showDrawerPreview &&
        <div className={styles.drawerPreviewWrapper}>
          <DrawerContent position={drawer} />
        </div>}
    </div>
  );
};

export const DrawerList = ({
  interactive = false,
  showDrawerPreview = false,
  showFreeSpaceText = false,
  showPriceWarning = false
}) => {
  const { drawers } = useTrolleySelectedData();
  const foams = Array.from(Array(drawers), (_, index) => {
    const drawer = index + 1;
    return FoamListForDrawer(
      drawer,
      interactive,
      showDrawerPreview,
      showFreeSpaceText,
      showPriceWarning
    );
  });
  return (
    <React.Fragment>
      {showPriceWarning && <PriceWarningText />}
      {foams}
    </React.Fragment>
  );
};

export const DrawerListWithCapacity = ({
  interactive = true,
  showFreeSpaceText = true,
  showPriceWarning = false
}) =>
  DrawerList({
    interactive,
    showFreeSpaceText,
    showPriceWarning
  });

DrawerList.propTypes = DrawerListWithCapacity.propTypes = {
  interactive: PropTypes.bool,
  showDrawerPreview: PropTypes.bool,
  showFreeSpaceText: PropTypes.bool,
  showPriceWarning: PropTypes.bool
};
