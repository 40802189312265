import { useQuery } from '@apollo/react-hooks';

import { useMemo } from 'react';

import { gql } from 'apollo-boost';

import { productFieldsForListItem } from '../queries/productFields';
import { accessoryFromGraphQlProductInterface } from '../../model/accessory';
import useStoreViewCode from '..//../app/hooks/useStoreViewCode';
import {useLocale} from '../../app/hooks/useLocale';
import config from '../../config';

const query = gql`
  query Accessories($trolleyCode: String) {
    betms_accessories(trolley_code: $trolleyCode) {
      items {
        ${productFieldsForListItem}
      }
    }
  }
`;

const useGetAccessoriesQuery = trolleyCode => {
  const storeViewCode = useStoreViewCode();
  const locale = useLocale();
  const { data, loading, error } = useQuery(query, {
    variables: { trolleyCode },
    context: {
      headers: {
        Store: storeViewCode
      },
      uri: config.localizedGraphQlServerUrl(locale)
    }
  });

  const modeledData = useMemo(
    () => {
      const items = (data && data.betms_accessories && data.betms_accessories.items) || [];
      return items.map(accessoryFromGraphQlProductInterface);
    },
    [data]
  );

  return {data: modeledData, loading, error};
};

export default useGetAccessoriesQuery;
