import { useEffect, useState, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { queryHooks } from '../../graphql';
import { selectors } from '../../redux';
import { colorFromId } from '../../model/color';
import { useQueryFoamAll } from '../../graphql/hooks/useQueryProduct';

export const useCartAccessoryIds = () =>
  useSelector(selectors.cart.getAccessoriesInCartIds);

export const useCartFoamIds = () =>
  useSelector(selectors.cart.getFoamsInCartIds);

/* 
 * IS PRODUCT IN CART
 * ------------------------------------------------------------------------- */

export const useIsAccessoryInCart = accessory =>
  useIsProductInCart(accessory, useCartAccessoryIds);

export const useIsFoamInCart = foam => useIsProductInCart(foam, useCartFoamIds);

const useIsProductInCart = (product, useCartProductIds) => {
  const [isInCart, setIsInCart] = useState(false);
  const addedProductIds = useCartProductIds();

  useEffect(
    () => {
      setIsInCart(addedProductIds.includes(product && product.id));
    },
    [product, addedProductIds]
  );

  return isInCart;
};

/* 
 * GET ACCESSORIES
 * ------------------------------------------------------------------------- */

export const useAccessoriesInCart = () => {
  const hubId = useSelector(selectors.cart.getHubId);
  return useAccessoriesInCartForHub(hubId);
};

export const useAccessoriesInCartForHub = (hubId) => {
  const addedAccessoriesIds = useCartAccessoryIds();
  const { data } = queryHooks.useGetAccessoriesQuery(hubId);
  return data.filter(item => addedAccessoriesIds.includes(item.id));
};

/* 
 * GET FOAMS
 * ------------------------------------------------------------------------- */

const preserveFoamOrder = (foamIds, foamModels) =>
  foamModels.sort((a, b) => foamIds.indexOf(a.id) - foamIds.indexOf(b.id));

export const useFoamsInDrawer = drawer => {
  const getDrawerFoamIds = useSelector(selectors.cart.makeGetDrawerFoamIds);
  const foamIds = getDrawerFoamIds(drawer);
  const { data } = useQueryFoamAll(...foamIds);
  const foams = preserveFoamOrder(foamIds, data);
  return foams;
};

export const useFoamsInCurrentDrawer = () => {
  const foamIds = useSelector(selectors.cart.getCurrentDrawerFoamIds);
  const { data } = useQueryFoamAll(...foamIds);
  const foams = preserveFoamOrder(foamIds, data);
  return foams;
};

export const useFoamsInCart = () => {
  const { data } = queryHooks.useGetFoamsQuery();
  const foamIdsInCart = useCartFoamIds();
  const foamsInCart = useMemo(
    () => {
      return data.filter(foam => foamIdsInCart.includes(foam.id));
    },
    [foamIdsInCart, data]
  );
  return foamsInCart;
};

const useFoamsInCartMap = () => {
  const foamsArray = useFoamsInCart();
  return useMemo(
    () => foamsArray.reduce((acc, curr) => (acc[curr.id] = curr, acc), {}), [foamsArray]);
};

export const useFoamsByDrawer = () => {
  const foamsInCartMap = useFoamsInCartMap();
  const foamsIdsByDrawer = useSelector(selectors.cart.getFoamIdsByDrawer);
  const foamsByDrawer = useMemo(() =>
    foamsIdsByDrawer.map(drawerFoams => drawerFoams.map(foamId => foamsInCartMap[foamId])), [foamsIdsByDrawer, foamsInCartMap]);
  return foamsByDrawer;
};

/* 
 * TROLLEY SELECTED DATA
 * ------------------------------------------------------------------------- */

export const useTrolleySelectedData = () => {
  const colorId = useSelector(selectors.cart.getColor);
  const hubId = useSelector(selectors.cart.getHubId);
  const drawers = useSelector(selectors.cart.getCartDrawers);

  return {
    color: colorId && colorFromId(colorId),
    drawers,
    hubId
  };
};

export const useConfiguredCart = () => {
  return useSelector(selectors.cart.getConfiguredCart);
}