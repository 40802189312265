import React from 'react';

import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import classnames from 'classnames/bind';

import FoamSize from '../FoamSize';
import Price from '../Price';
import { ProductDetailsHeading, ProductDetailsContent } from '../ProductDetails';
import { ProductTechnicalDetailsHeading, ProductTechnicalDetailsContent } from '../ProductTechnicalDetails';
import { SnaPropTypes } from '../../propTypes';

import messages from './messages';
import styles from './styles.css';

const visuallyHiddenFallbackForCssModule = cssmodule => {
  const cx = classnames.bind(cssmodule);
  return className =>
    cx({
      'visually-hidden': cssmodule[className] === undefined,
      [className]: cssmodule[className] !== undefined
    });
};

const ProductData = ({ 
  classNames = [],
  cssmodule,
  imageType, 
  intl,
  product, 
  ProductImage
}) => {
  const visuallyHiddenFallBack = visuallyHiddenFallbackForCssModule(cssmodule);
  return (
    product &&
    <dl className={classnames(...classNames, styles.ProductData, cssmodule.ProductData)}>
      <dt className={visuallyHiddenFallBack('ProductData__dt-name')}>
        {intl.formatMessage(messages.name)}
      </dt>
      <dd className={cssmodule.ProductData__name}>
        {product.name}
      </dd>
      <dt className={visuallyHiddenFallBack('ProductData__dt-sku')}>
        {intl.formatMessage(messages.sku)}
      </dt>
      <dd className={cssmodule.ProductData__sku}>
        {product.sku}
      </dd>
      <dt className={visuallyHiddenFallBack('ProductData__dt-price')}>
        {intl.formatMessage(messages.price)}
      </dt>
      <dd className={cssmodule.ProductData__price}>
        <Price value={product.price} />
      </dd>
      <dt className={visuallyHiddenFallBack('ProductData__dt-image')}>
        {intl.formatMessage(messages.productImage)}
      </dt>
      <dd className={cssmodule.ProductData__img}>
        <ProductImage
          product={product}
          imageType={imageType}
        />
      </dd>
      {product.size &&
        <React.Fragment>
          <dt className={visuallyHiddenFallBack('ProductData__dt-size')}>
            {intl.formatMessage(messages.size)}
          </dt>
          <dd className={cssmodule.ProductData__size}>
            <FoamSize foam={product} />
          </dd>
        </React.Fragment>}
      {product.details &&
        <React.Fragment>
          <dt className={visuallyHiddenFallBack('ProductData__dt-details')}>
            <ProductDetailsHeading />
          </dt>
          <dd className={cssmodule.ProductData__details}>
            <ProductDetailsContent details={product.details} />
          </dd>
      </React.Fragment> }
      {product.technicalDetails &&
        <React.Fragment>
          <dt className={visuallyHiddenFallBack('ProductData__dt-technicalDetails')}>
            <ProductTechnicalDetailsHeading />
          </dt>
          <dd className={cssmodule.ProductData__technicalDetails}>
            <ProductTechnicalDetailsContent product={product} />
          </dd>
      </React.Fragment> }
    </dl>
  );
};

ProductData.propTypes = {
  classNames: PropTypes.arrayOf(PropTypes.string),
  cssmodule: PropTypes.any.isRequired,
  imageType: SnaPropTypes.imageType,
  product: SnaPropTypes.product.isRequired,
  ProductImage: PropTypes.elementType,
};

export default injectIntl(ProductData);
