import { useCallback } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';

import { SnaPropTypes } from '../../../propTypes';
import { operations } from '../../../../redux';
import { useIsFoamInCart, useIsAccessoryInCart } from '../../../hooks/cart';
import {
  useSelectedDrawer,
  useDispatchAddFoamToDrawerIfItFits,
  useDispatchMoveFoamToDrawerIfItFits
} from '../../../hooks/drawer';

import AddToCart from './Default';
import AddToCartMini from './Mini';
import messages from './messages';

const useToggleAccessoryInCart = (product, clickExtraActions) => {
  const dispatch = useDispatch();
  const isInCart = useIsAccessoryInCart(product);
  return useCallback(
    () => {
      clickExtraActions && clickExtraActions();
      isInCart
        ? dispatch(operations.cart.removeAccessoryFromCart(product))
        : dispatch(operations.cart.addAccessoryToCart(product));
    },
    [clickExtraActions, dispatch, isInCart, product]
  );
};

const useToggleFoamInCart = (product, drawer, moveFromAnotherDrawer, clickExtraActions) => {
  const currentDrawer = useSelectedDrawer();
  drawer = drawer || currentDrawer;
  const dispatch = useDispatch();
  const dispatchAddFoamToDrawer = useDispatchAddFoamToDrawerIfItFits();
  const dispatchMoveFoamToDrawer = useDispatchMoveFoamToDrawerIfItFits();
  const isInCart = useIsFoamInCart(product);
  return useCallback(
    () => {
      if (moveFromAnotherDrawer) {
        dispatchMoveFoamToDrawer(product, drawer);
      } else {
        isInCart
          ? dispatch(operations.cart.removeFoam(product))
          : dispatchAddFoamToDrawer(product, drawer);
      }
      clickExtraActions && clickExtraActions();
    },
    [clickExtraActions, dispatch, dispatchAddFoamToDrawer, dispatchMoveFoamToDrawer, drawer, isInCart, moveFromAnotherDrawer, product]
  );
};

const AddAccessoryToCart = props => {
  const { product, onClick } = props;
  const clickHandler = useToggleAccessoryInCart(product, onClick);
  const isInCart = useIsAccessoryInCart(product);
  return AddToCart({
    ...props,
    clickHandler,
    isInCart,
    messages: {
      add: messages.addAccessory,
      remove: messages.removeAccessory
    }
  });
};

const AddAccessoryToCartMini = props => {
  const { product, drawer, onClick } = props;
  const clickHandler = useToggleAccessoryInCart(product, drawer, onClick);
  const isInCart = useIsAccessoryInCart(product);
  return AddToCartMini({
    ...props,
    clickHandler,
    isInCart,
    messages: {
      add: messages.addAccessoryX,
      remove: messages.removeAccessoryX
    }
  });
};

const AddFoamToCart = props => {
  const { product, drawer, moveFromAnotherDrawer = false, onClick } = props;
  const clickHandler = useToggleFoamInCart(product, drawer, moveFromAnotherDrawer, onClick);
  const isInCart = useIsFoamInCart(product);
  return AddToCart({
    ...props,
    clickHandler,
    forceAddMessage: moveFromAnotherDrawer,
    isInCart,
    messages: {
      add: moveFromAnotherDrawer ? messages.moveFoamToDrawer : messages.addFoam,
      remove: messages.removeFoam
    }
  });
};

const AddFoamToCartMini = props => {
  const { product, drawer, moveFromAnotherDrawer = false, onClick } = props;
  const clickHandler = useToggleFoamInCart(product, drawer, moveFromAnotherDrawer, onClick);
  const isInCart = useIsFoamInCart(product);
  return AddToCartMini({
    ...props,
    clickHandler,
    forceAddMessage: moveFromAnotherDrawer,
    isInCart,
    messages: {
      add: moveFromAnotherDrawer ? messages.moveFoamXToDrawer : messages.addFoamX,
      remove: messages.removeFoamX
    }
  });
};

AddAccessoryToCart.propTypes = AddAccessoryToCartMini.propTypes = {
  className: SnaPropTypes.className,
  cssModule: SnaPropTypes.cssModule,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  product: SnaPropTypes.accessory.isRequired
};

AddFoamToCart.propTypes = AddFoamToCartMini.propTypes = {
  className: SnaPropTypes.className,
  cssModule: SnaPropTypes.cssModule,
  disabled: PropTypes.bool,
  drawer: SnaPropTypes.drawer,
  moveFromAnotherDrawer: PropTypes.bool,
  onClick: PropTypes.func,
  product: SnaPropTypes.foam.isRequired
};

export {
  AddAccessoryToCart,
  AddAccessoryToCartMini,
  AddFoamToCart,
  AddFoamToCartMini
};
