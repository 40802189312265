import PropTypes from 'prop-types';

import {
  priceFromGraphQlPrice,
  propType as pricePropType
} from './price';

const product = ({
  detailImage,
  name,
  previewImage,
  price,
  thumbnail,
  sku,
  details,
  technicalDetails
}) => {
  return {
    id: sku,
    detailImage,
    name,
    previewImage,
    price,
    sku,
    thumbnail,
    details,
    technicalDetails
  };
};

const technicalDetailsFromGraphQlSnaCatalogAttributeArray = arr =>
  arr
    ? arr.map(attr => ({
        iconUrl: attr.icon_url,
        id: attr.id,
        label: attr.label,
        value: attr.value
      }))
    : null;

const betmsImageSizes = {
  BIG: 'big',
  SMALL: 'small',
  THUMBNAIL: 'thumbnail',
  ORIGINAL: 'original'
}
const imageUrlFromGraphQlBetmsImage = (item, imgSize) => item && item.betms_image && item.betms_image[imgSize] && item.betms_image[imgSize].url || '';

export const productFromGraphQlProductInterface = item =>
  product({
    name: item.name,
    previewImage: imageUrlFromGraphQlBetmsImage(item, betmsImageSizes.ORIGINAL),
    price: priceFromGraphQlPrice(item.betms_price),
    sku: item.sku,
    thumbnail: imageUrlFromGraphQlBetmsImage(item, betmsImageSizes.ORIGINAL),
    details: item.details,
    technicalDetails: technicalDetailsFromGraphQlSnaCatalogAttributeArray(
      item.technical_details
    )
  });

export const propTypeRawShapeToExtend = {
  id: PropTypes.string.isRequired,
  detailImage: PropTypes.string,
  name: PropTypes.string.isRequired,
  previewImage: PropTypes.string,
  price: pricePropType,
  sku: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  details: PropTypes.arrayOf(PropTypes.string),
  technicalDetails: PropTypes.arrayOf(
    PropTypes.shape({
      icon_url: PropTypes.string,
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string
    })
  )
};

export const propType = PropTypes.shape(propTypeRawShapeToExtend);
