import React from 'react';

import { FormattedMessage } from 'react-intl';

import PropTypes from 'prop-types';

import classNames from 'classnames/bind';

import messages from './messages';
import styles from './styles.css';

const CloseButton = ({ className, onClick }) => {
  const cx = classNames.bind(styles);

  return (
    <button
      className={cx({
        ...(className && { [className]: true }),
        CloseButton: true
      })}
      onClick={onClick}
    >
      <span
        className={cx({
          CloseButton__icon: true
        })}
      />
      <span className="visually-hidden">
        <FormattedMessage {...messages.close} />
      </span>
    </button>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func
};

export default CloseButton;
