import React, { useCallback } from 'react';

import { useDrop } from 'react-dnd';

import { useDispatch } from 'react-redux';

import classNames from 'classnames/bind';

import { SnaPropTypes } from '../../propTypes';
import config from '../../../config';
import { operations } from '../../../redux';
import { useFoamsInDrawer } from '../../hooks/cart';
import { useDispatchAddFoamToDrawerIfItFits } from '../../hooks/drawer';
import useAppImageSizes, { imageTypes } from '../../hooks/useAppImageSizes';
import useImageUrlBase from '../../hooks/useImageUrlBase';

import styles from './Drawer.module.css';
import { DraggableFoam, Foam } from './Foam';
import ErrorBoundary from '../ErrorBoundary';

const useAddFoam = drawer => {
  const dispatchAddFoamToDrawer = useDispatchAddFoamToDrawerIfItFits();
  return useCallback(
    foam => {
      dispatchAddFoamToDrawer(foam, drawer);
    },
    [dispatchAddFoamToDrawer, drawer]
  );
};

const useMoveFoam = drawer => {
  const dispatch = useDispatch();
  return useCallback(
    ({ oldIndex, newIndex }) => {
      dispatch(operations.cart.reorderFoams(drawer, oldIndex, newIndex));
    },
    [dispatch, drawer]
  );
};

export const DrawerEditable = ({ position }) => {
  const addFoam = useAddFoam(position);
  const moveFoam = useMoveFoam(position);
  const cx = classNames.bind(styles);

  const [{ isOver }, drop] = useDrop({
    accept: [config.draggableItemTypes.foamFromList],
    drop({ foam }) {
      addFoam(foam);
    },
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  });

  return Drawer({
    className: cx({ isFoamDropping: isOver }),
    moveFoam,
    position,
    ref: drop
  });
};

DrawerEditable.propTypes = {
  position: SnaPropTypes.drawer.isRequired
};

export const Drawer = ({ className, moveFoam, position, ref }) => {
  const foams = useFoamsInDrawer(position);

  const drawerBg = useImageUrlBase('bg-foam-preview.png');
  const { height, width } = useAppImageSizes(imageTypes.FOAM_PREVIEW_SIZE_3);
  const cx = classNames.bind(styles);

  return (
    <ErrorBoundary>
      <ol
        className={cx(className, { Drawer: true })}
        ref={ref}
        style={{
          borderImageSource: `url(${drawerBg})`,
          width: width + 'px',
          height: height + 'px'
        }}
      >
        {foams.map(
          (item, index) =>
            moveFoam
              ? <DraggableFoam
                key={`sortable-foam-${index}`}
                index={index}
                moveFoam={moveFoam}
                foam={item}
              />
              : <Foam key={`sortable-foam-${index}`} index={index} foam={item} />
        )}
      </ol>
    </ErrorBoundary>
  );
};

Drawer.propTypes = {
  position: SnaPropTypes.drawer.isRequired
};
