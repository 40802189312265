import React from 'react';

import { injectIntl } from 'react-intl';

import classNames from 'classnames';

import { SnaPropTypes } from '../../propTypes';
import { useShouldShowPrice } from '../../hooks/useShouldShowPrice';

import messages from './messages';
import styles from './styles.css';

export const PriceWarningAsterisk = injectIntl(({ intl }) =>
  <span
    className={styles.asterisk}
    aria-describedby={intl.formatMessage(messages.pricesWarning)}
  >
    *
  </span>
);

export const PriceWarningText = injectIntl(({ cssModule = {}, intl }) => {
  const { data: shouldShowPrice } = useShouldShowPrice();
  return shouldShowPrice
    ? <p className={classNames(cssModule.PriceWarning, styles.PriceWarning)}>
        * {intl.formatMessage(messages.pricesWarning)}
      </p>
    : null;
});

PriceWarningText.propTypes = {
  cssModule: SnaPropTypes.cssModule
};
