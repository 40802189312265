import React from 'react';

import classNames from 'classnames/bind';
import { NavLink } from 'react-router-dom';
import styles from './Step.module.css';
import { injectIntl } from 'react-intl';

const Step = injectIntl(({ disabled, summary, title, url, intl }) => {
  const handleClick = e => {
    disabled && e.preventDefault();
  };
  const cx = classNames.bind(styles);
  return (
    <li className={styles.step}>
      <NavLink
        to={url}
        className={cx('step__link')}
        activeClassName={cx('step__link--active')}
        onClick={handleClick}
        disabled={disabled}
      >
        <span className={styles.step__summary}>
          {summary.color &&
            <span
              className={styles.step__colorIcon}
              style={{ backgroundColor: summary.color.hexcode }}
            >
              <span className="visually-hidden">
                {summary.color.toString(intl)}
              </span>
            </span>}
          {summary.text}
          {summary.icon}
        </span>
        <span className={styles.step__name}>
          {title}
        </span>
      </NavLink>
    </li>
  );
});

export default Step;
