import types from './types';
import config from '../../config';
import { storeViewLocaleFromStoreViewCode } from '../../utils/store-view';
import { getMessagesForStoreViewLocale } from '../../i18n';

const stateFromStoreViewCode = storeViewCode => {
  const locale = storeViewLocaleFromStoreViewCode(storeViewCode);
  return {
    code: storeViewCode,
    locale,
    messages: getMessagesForStoreViewLocale(locale)
  };
};

const initialState = stateFromStoreViewCode(config.storeViewCodes.default);

const nextStateByType = {
  [types.CHANGE_STORE_VIEW]: (_, action) =>
    stateFromStoreViewCode(action.payload)
};

const reducer = (state = initialState, action) => {
  const nextState = nextStateByType[action.type] || (state => state);
  return nextState(state, action);
};

export default reducer;
