import React from 'react';

import { PriceWarningAsterisk } from '../PriceWarning';
import { SnaPropTypes } from '../../propTypes';
import useCurrency from '../../hooks/useCurrency';
import { useShouldShowPrice } from '../../hooks/useShouldShowPrice';
import { priceNull, formatPrice } from '../../../model/price';
import { injectIntl } from 'react-intl';

const Price = injectIntl(({ intl, value }) => {
  const shouldShowPrice = useShouldShowPrice();
  const currency = useCurrency();
  return (
    shouldShowPrice.data &&
    value !== priceNull &&
    <span>
      {formatPrice(intl, value, currency)} <PriceWarningAsterisk />
    </span>
  );
});

Price.propTypes = {
  value: SnaPropTypes.price.isRequired
};

export default Price;
