import React from 'react';

import classNames from 'classnames';

import Image from '../Image';
import { SnaPropTypes } from '../../propTypes';
import useAppImageSizes from '../../hooks/useAppImageSizes';

import styles from './styles.css';
import FoamImage from '../FoamImage';

const ProductImage = ({ alt, className, image, imageType }) => {
  const { height, width } = useAppImageSizes(imageType);
  const style = { height: height + 'px', width: width + 'px' };
  return (
    <figure
      className={classNames(className, styles.ProductImage)}
      style={style}
    >
      {image}
      <figcaption className="visually-hidden">
        {alt}
      </figcaption>
    </figure>
  );
};

export const AccessoryProductImage = ({ product, className, imageType }) => {
  const { name: alt, thumbnail: src } = product;
  return ProductImage({
    alt,
    className,
    image: (
      <Image
        alt={alt}
        className={styles.ProductImage__img}
        lazy={true}
        src={src}
      />
    ),
    imageType
  });
};

export const FoamProductImage = ({ product, className, imageType }) => {
  const { name: alt, thumbnail: src } = product;
  return ProductImage({
    alt,
    className: classNames(className, styles.isFoam),
    image: (
      <FoamImage
        foam={product}
        alt={alt}
        className={styles.ProductImage__img}
        lazy={true}
        src={src}
      />
    ),
    imageType
  });
};

AccessoryProductImage.propTypes = FoamProductImage.propTypes = {
  product: SnaPropTypes.product,
  className: SnaPropTypes.className,
  imageType: SnaPropTypes.imageType.isRequired
};
