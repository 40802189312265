import { defineMessages } from 'react-intl';

export default defineMessages({
  product: {
    id: 'product',
    defaultMessage: 'Product'
  },
  productTechnicalDetails: {
    id: 'productTechnicalDetails',
    defaultMessage: 'Technical details'
  }
});
