import { defineMessages } from 'react-intl';

export default defineMessages({
  freeSpace: {
    id: 'freeSpace',
    defaultMessage: '{space} free space'
  },
  full: {
    id: 'full',
    defaultMessage: 'Full'
  }
});
