import { defineMessages } from "react-intl";

export default defineMessages({
    resetDrawer: {
    id: 'resetDrawer',
    defaultMessage: 'Reset this drawer'
  },
  resetAll: {
    id: 'resetAll',
    defaultMessage: 'Reset all'
  }
});