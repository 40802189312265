import React from 'react';

import SummaryTabs from './Tabs';

import SaveConfigButton from './SaveConfigButton';
import styles from './styles.css';

const SummaryPage = () => {
  return (
    <article className={styles.page}>
      <SummaryTabs />
      <SaveConfigButton />
    </article>
  );
};

export default SummaryPage;
