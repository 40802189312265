import types from './types';

const changeStoreView = storeViewCode => ({
  type: types.CHANGE_STORE_VIEW,
  payload: storeViewCode
});

export default {
  changeStoreView
};
