import React from 'react';

const SummaryIcon = ({className}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" className={className} >
    <path id="Shape" d="M4.15,7.32c1.19,0,2.15-0.97,2.15-2.15S5.34,3.01,4.15,3.01S2,3.98,2,5.16S2.97,7.32,4.15,7.32z
         M4.15,4.01c0.64,0,1.15,0.52,1.15,1.15S4.78,6.31,4.15,6.31S3,5.79,3,5.16S3.52,4.01,4.15,4.01z"/>
    <path d="M4.15,13.96c1.19,0,2.15-0.97,2.15-2.15S5.34,9.66,4.15,9.66S2,10.62,2,11.81S2.97,13.96,4.15,13.96z
         M4.15,10.66c0.64,0,1.15,0.52,1.15,1.15c0,0.63-0.52,1.15-1.15,1.15S3,12.45,3,11.81C3,11.17,3.52,10.66,4.15,10.66z"/>
    <path d="M4.15,20.61c1.19,0,2.15-0.97,2.15-2.15c0-1.19-0.97-2.15-2.15-2.15S2,17.28,2,18.46
        C2,19.64,2.97,20.61,4.15,20.61z M4.15,17.3c0.64,0,1.15,0.52,1.15,1.15c0,0.64-0.52,1.15-1.15,1.15S3,19.09,3,18.46
        C3,17.82,3.52,17.3,4.15,17.3z"/>
    <path id="Path" d="M9.18,5.66h12.31c0.28,0,0.5-0.22,0.5-0.5c0-0.28-0.22-0.5-0.5-0.5H9.18c-0.28,0-0.5,0.22-0.5,0.5
        C8.68,5.44,8.9,5.66,9.18,5.66z"/>
    <path d="M9.18,12.31h12.31c0.28,0,0.5-0.22,0.5-0.5c0-0.28-0.22-0.5-0.5-0.5H9.18c-0.28,0-0.5,0.22-0.5,0.5
        C8.68,12.09,8.9,12.31,9.18,12.31z"/>
    <path d="M9.18,18.96h12.31c0.28,0,0.5-0.22,0.5-0.5c0-0.28-0.22-0.5-0.5-0.5H9.18c-0.28,0-0.5,0.22-0.5,0.5
        C8.68,18.73,8.9,18.96,9.18,18.96z"/>
    </svg>
);

export default SummaryIcon;