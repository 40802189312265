import React from 'react';

import { injectIntl } from 'react-intl';

import { PrimaryGhostButton } from '../PrimaryButton';
import { SnaPropTypes } from '../../../propTypes';
import config from '../../../../config';

import messages from './messages';
import { useLocale } from '../../../hooks/useLocale';

const SendRequestButton = injectIntl(
  ({ buttonClassName, componentClassName, intl }) => {
    const locale = useLocale();
    const href = config.urlTo.sendRequest(locale);
    return (
      <PrimaryGhostButton
        componentClassName={componentClassName}
        buttonClassName={buttonClassName}
        href={href}
        target="_blank"
      >
        {intl.formatMessage(messages.sendARequest)}
      </PrimaryGhostButton>
    );
  }
);

SendRequestButton.propTypes = {
  className: SnaPropTypes.className
};

export default SendRequestButton;
