import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { operations, selectors } from '../../redux';
import { storeViewCodeFromStoreViewLocale } from '../../utils/store-view';

export const useLocale = () => useSelector(selectors.storeView.getLocale);

export const useDispatchChangeLocale = () => {
  const dispatch = useDispatch();
  return useCallback(
    locale =>
      dispatch(
        operations.storeView.changeStoreView(
          storeViewCodeFromStoreViewLocale(locale)
        )
      ),
    [dispatch]
  );
};

export const useLocalizeRouterPathCallback = () => {
  const locale = useLocale();
  return useCallback(path => `/${locale}/${path}`, [locale]);
};
