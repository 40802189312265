import React, { useRef } from 'react';

import { useDrop, useDrag } from 'react-dnd';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import FoamImage from '../FoamImage';
import { SnaPropTypes } from '../../propTypes';
import { AddFoamToCartMini } from '../buttons/AddToCart';
import config from '../../../config';
import { useFoamPreviewImageSizes } from '../../hooks/useAppImageSizes';

import styles from './Foam.module.css';

const useFoamDragDrop = ({ id, foam, index, itemType, moveFoam, ref }) => {
  const [, drop] = useDrop({
    accept: [config.draggableItemTypes.foamPreview],
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      if (item.type === config.draggableItemTypes.foamFromList) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }
      // Time to actually perform the action
      moveFoam({
        oldIndex: dragIndex,
        newIndex: hoverIndex
      });
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ draggingId, isDragging }, drag] = useDrag({
    item: { type: itemType, id, index, foam },
    collect: monitor => ({
      draggingId: monitor.getItem() && monitor.getItem().id,
      isDragging: monitor.isDragging()
    })
  });

  drag(drop(ref));

  return {
    drag,
    drop,
    draggingId,
    isDragging
  };
};

export const Foam = ({
  className,
  enableRemoving = false,
  foam,
  ref,
  style
}) => {
  const { name } = foam;

  const { height, width } = useFoamPreviewImageSizes(foam);

  return (
    <li ref={ref} style={style} className={classNames(className, styles.Foam)}>
      <figure
        className={styles.FoamPreview}
        style={{ width: width + 'px', height: height + 'px' }}
      >
        <FoamImage className={styles.FoamPreview__img} foam={foam} />
        <figcaption className="visually-hidden">
          {name}
        </figcaption>
      </figure>
      {enableRemoving &&
        <AddFoamToCartMini cssModule={styles} product={foam} />}
    </li>
  );
};

Foam.propTypes = {
  enableRemoving: PropTypes.bool,
  foam: SnaPropTypes.foam.isRequired,
  ref: PropTypes.object,
  style: PropTypes.object
};

export const DraggableFoam = ({ foam, index, moveFoam }) => {
  const { id } = foam;

  const ref = useRef(null);
  const { draggingId } = useFoamDragDrop({
    id,
    foam,
    index,
    itemType: config.draggableItemTypes.foamPreview,
    moveFoam,
    ref
  });

  const style = {
    opacity: draggingId === id ? 0.6 : 1
  };

  return Foam({
    className: styles.DraggableFoam,
    enableRemoving: true,
    foam,
    ref,
    style
  });
};

DraggableFoam.propTypes = {
  foam: SnaPropTypes.foam.isRequired,
  index: PropTypes.number.isRequired,
  moveFoam: PropTypes.func.isRequired
};
