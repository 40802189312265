const RESET_SELECTED_DRAWER = 'RESET_SELECTED_DRAWER';
const SELECT_DRAWER = 'SELECT_DRAWER';

const initialState = 1;

const nextStateByType = {
  [RESET_SELECTED_DRAWER]: () => initialState,
  [SELECT_DRAWER]: (_, payload) => payload
};

const reducer = (state = initialState, action) => {
  const nextState = nextStateByType[action.type] || (state => state);
  return nextState(state, action.payload);
};

const selectDrawer = drawer => ({
  type: SELECT_DRAWER,
  payload: drawer
});

const resetSelectedDrawer = () => ({
  type: RESET_SELECTED_DRAWER
});

export const operations = {
  resetSelectedDrawer,
  selectDrawer
};

export const selectors = {
  getSelectedDrawer: state => state.foams.selectedDrawer
}

export default reducer;
