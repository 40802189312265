import React from 'react';

import { injectIntl } from 'react-intl';

import { useDispatch, useSelector } from 'react-redux';

import { Form, Field } from 'react-final-form';

import { operations, selectors } from '../../../../redux';
import { useSearchText } from '../../../hooks/foams';
import { FOAM_SIZE_LABELS } from '../../../../model/foam';
import { Checkbox } from '../../../components/forms/Checkbox';
import SearchIcon from '../../../components/icons/SearchIcon';

import messages from './messages';
import styles from './styles.css';

const FoamSizeFilter = () => {
  const sizesFilter = useSelector(selectors.foams.sizesFilter);
  const dispatch = useDispatch();
  const setChecked = (checked, index) =>
    dispatch(operations.foams.setCheckedSizeAt({ checked, index }));
  return (
    <Form
      onSubmit={() => {}}
      render={({ handleSubmit }) =>
        <form onSubmit={handleSubmit} className={styles.sizesFilter}>
          {FOAM_SIZE_LABELS.map((val, index) =>
            <Checkbox
              name={'foamSize' + index}
              label={val}
              key={index}
              cssmodule={styles}
              value={sizesFilter[index]}
              onChange={({ checked }) => setChecked(checked, index)}
              checked={sizesFilter[index]}
            />
          )}
        </form>}
    />
  );
};

const FoamSearchForm = injectIntl(({ intl }) => {
  const searchText = useSearchText();
  const dispatch = useDispatch();
  const setFoamSearchText = searchText =>
    dispatch(operations.foams.setSearchText(searchText));
  return (
    <Form
      onSubmit={({ search }) => setFoamSearchText(search || '')}
      render={({ handleSubmit }) =>
        <form onSubmit={handleSubmit} className={styles.searchbox}>
          <Field
            component="input"
            name="search"
            onBlur={e => setFoamSearchText(e.target.value)}
            className={styles.searchbox__input}
            defaultValue={searchText}
            placeholder={intl.formatMessage(messages.searchNameOrReference)}
          />
          <button className={styles.searchbox__button}>
            <SearchIcon />
          </button>
        </form>}
    />
  );
});

/* const FoamIndustryFilter = injectIntl(({ intl }) => {
  const industriesSelection = useIndustriesSelection();
  const { data } = useAvailableIndustries();
  const dispatch = useDispatch();
  const selectIndustry = industry =>
    dispatch(operations.foams.setSelectedIndustry(industry));
  return (
    <Form
      onSubmit={() => { }}
      render={({ handleSubmit }) =>
        <form onSubmit={handleSubmit} className={styles.industriesFilter}>
          <Field
            name="foamIndustries"
            component="select"
            className={styles.industriesFilter__input}
            defaultValue={industriesSelection}
            onChange={event => {
              selectIndustry(event.target.value);
            }}
          >
            <option value="0">
              {intl.formatMessage(messages.allSectors)}
            </option>
            {data.map((val, index) => {
              const message = messages['foamIndustries' + val];
              return (
                message &&
                <option value={val} key={index}>
                  {intl.formatMessage(message)}
                </option>
              );
            })}
          </Field>
        </form>}
    />
  );
}); */

const Filters = () =>
  <div className={styles.Filters}>
    <FoamSearchForm />
    <FoamSizeFilter />
  </div>;

export default Filters;
