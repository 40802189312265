import { queryHooks } from '../../graphql';

import { useTrolleySelectedData } from './cart';

export const useSelectedTrolley = () => {
  const trolleyData = useTrolleySelectedData();
  return queryHooks.useQueryTrolley(trolleyData);
};

export const useSelectedTrolleyDetails = () => {
  const trolleyData = useTrolleySelectedData();
  return queryHooks.useQueryTrolley({ ...trolleyData, fetchDetailsData: true });
};
