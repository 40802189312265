import React, { useCallback } from 'react';

import { uid } from 'react-uid';

import { useDispatch, useSelector } from 'react-redux';

import config from '../config';
import { operations, selectors } from '../../redux';
import { storeViewCodeFromStoreViewLocale } from '../../utils/store-view';

const option = locale => {
  const props = {
    key: uid(locale),
    value: locale
  };
  return (
    <option {...props}>
      {locale}
    </option>
  );
};

const LanguageSelector = ({ className }) => {
  const selected = useSelector(selectors.storeView.getLocale);
  const locales = config.acceptedLocales;
  const dispatch = useDispatch();
  const changeLocale = useCallback(
    evt =>
      dispatch(
        operations.storeView.changeStoreView(
          storeViewCodeFromStoreViewLocale(evt.target.value)
        )
      ),
    [dispatch]
  );
  return (
    <label className={className}>
      lang:
      <select onChange={changeLocale} onBlur={changeLocale} value={selected}>
        {locales.map(loc => option(loc))}
      </select>
    </label>
  );
};

export default LanguageSelector;
