import { useQuery } from '@apollo/react-hooks';

import { gql } from 'apollo-boost';
import useStoreViewCode from '../../app/hooks/useStoreViewCode';
import {useLocale} from '../../app/hooks/useLocale';
import config from '../../config';

const query = gql`
  {
    cmsBlocks(identifiers: ["betms_configurator_hub_comparison_table"]) {
      items {
        identifier
        content
      }
    }
  }
`;

const useGetComparisonTableCmsBlockQuery = () => {
  const storeViewCode = useStoreViewCode();
  const locale = useLocale();
  const { data, loading, error } = useQuery(query, {
    context: {
      headers: {
        Store: storeViewCode
      },
      uri: config.localizedGraphQlServerUrl(locale)
    }
  });
  const cmsBlocks = (
    data &&
    data.cmsBlocks &&
    data.cmsBlocks.items &&
    data.cmsBlocks.items[0] &&
    data.cmsBlocks.items[0].content
  );
  return { data: cmsBlocks, loading, error };
};

export default useGetComparisonTableCmsBlockQuery;
