import config from '../../../config';

import types from './types';

const initialState = {
  foam: null,
  drawer: 1,
  type: config.drawersPopUpTypes.addTo
};

const nextStateByType = {
  [types.OPEN_DRAWERS_POP_UP]: (state, action) => ({
    ...state,
    ...action.payload
  }),
  [types.CLOSE_DRAWERS_POP_UP]: () => initialState
};

const reducer = (state = initialState, action) => {
  const nextState = nextStateByType[action.type] || (state => state);
  return nextState(state, action);
};

export default reducer;
