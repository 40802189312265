import React from 'react';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { FormattedMessage } from 'react-intl';

import messages from './messages';
import styles from '../styles.css';

const TabsSelectionAndSelected = ({ selectionPanel, selectedPanel }) =>
  <Tabs
    className={styles.tabs}
    forceRenderTabPanel={true}
    selectedTabPanelClassName={styles.isSelected}
  >
    <TabList className={styles.tablist}>
      <Tab className={styles.tablist__tab}>
        <FormattedMessage {...messages.select} />
      </Tab>
      <Tab className={styles.tablist__tab}>
        <FormattedMessage {...messages.seeSelected} />
      </Tab>
    </TabList>
    <TabPanel className={styles.tabpanel}>
      {selectionPanel}
    </TabPanel>
    <TabPanel className={styles.tabpanel}>
      {selectedPanel}
    </TabPanel>
  </Tabs>;

export default TabsSelectionAndSelected;
