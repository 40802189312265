import { useSelector } from 'react-redux';

import { selectors } from '../../../redux';
import { useAccessoriesInCart, useFoamsInCart } from '../../hooks/cart';
import { useSelectedTrolley } from '../../hooks/useSelectedTrolley';
import { nullPrice, sumPrices } from '../../../model/price';

export const useShouldShowSubtotal = () => {
  return useSelector(selectors.cart.getShouldShowSubtotal);
};

export const useSubtotal = () => {
  const trolleyPrice = useTrolleyPrice();
  const accessoriesSubtotal = useAccessoriesSubtotal();
  const foamsSubtotal = useFoamsSubtotal();
  return sumPrices(trolleyPrice, accessoriesSubtotal, foamsSubtotal);
};

export const useTrolleyPrice = () => {
  const { data } = useSelectedTrolley();
  return (data && data.price) || nullPrice;
};

export const useAccessoriesSubtotal = () => {
  const accessories = useAccessoriesInCart();
  if (!accessories || !accessories.length) return nullPrice;
  const accessoriesPrices = accessories.map(accessory => accessory.price);
  return sumPrices(...accessoriesPrices, nullPrice);
};

export const useFoamsSubtotal = () => {
  const foams = useFoamsInCart();
  if (!foams || !foams.length) return nullPrice;
  const foamsPrices = foams.map(foam => foam.price);
  return sumPrices(...foamsPrices, nullPrice);
};
