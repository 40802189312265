import types from './types';

/* ACCESSORIES */
const addAccessoryToCart = accessory => ({
  type: types.ADD_ACCESSORY_TO_CART,
  payload: accessory
});

const removeAccessoryFromCart = accessory => ({
  type: types.REMOVE_ACCESSORY_FROM_CART,
  payload: accessory
});

const setCartAccessoryIds = accessoryIds => ({
  type: types.SET_CART_ACCESSORIES,
  payload: accessoryIds
});

/* FOAMS */
const addFoamToDrawer = (foam, drawer) => ({
  type: types.ADD_FOAM,
  payload: { foam, drawer }
});

const removeFoam = foam => ({
  type: types.REMOVE_FOAM,
  payload: foam
});

const reorderFoams = (drawerPosition, oldIndex, newIndex) => ({
  type: types.REORDER_FOAMS,
  payload: { drawerPosition, oldIndex, newIndex }
});

const resetAllDrawers = () => ({
  type: types.RESET_ALL_DRAWERS
});

const resetDrawer = (drawer) => ({
  type: types.RESET_DRAWER,
  payload: drawer
});

const setDrawersLimit = (drawers) => ({
  type: types.SELECT_DRAWERS_LIMIT,
  payload: drawers
});

/* HUB */
const selectChest = (chest = false) => ({
  type: types.SELECT_CHEST,
  payload: {
    chest
  }
});

const selectDrawers = num => ({
  type: types.SELECT_DRAWERS,
  payload: {
    drawers: num * 1
  }
});

const selectHub = (model, color) => ({
  type: types.SELECT_HUB,
  payload: {
    model,
    color
  }
});

/* CART */
const setCart = cart => ({
  type: types.SET_CART,
  payload: {
    cart
  }
});

export default {
  addAccessoryToCart,
  removeAccessoryFromCart,
  setCartAccessoryIds,
  addFoamToDrawer,
  removeFoam,
  reorderFoams,
  resetAllDrawers,
  resetDrawer,
  setDrawersLimit,
  selectChest,
  selectDrawers,
  selectHub,
  setCart,
};
