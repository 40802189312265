import { defineMessages } from "react-intl";

export default defineMessages({
  select: {
    id: "select",
    defaultMessage: "select"
  },
  seeSelected: {
    id: "see selected",
    defaultMessage: "see selected"
  }
});