import React from 'react';

import { injectIntl } from 'react-intl';

import SendRequestButton from '../buttons/SendRequestButton';

import messages from './messages';
import styles from './styles';

const SendRequestInfo = injectIntl(({ intl }) =>
  <React.Fragment>
    <p className={styles.text}>
      {intl.formatMessage(messages.sendARequestAlertText)}
    </p>
    <div className={styles.fakeHeader}>
      <SendRequestButton />
    </div>
  </React.Fragment>
);

export default SendRequestInfo;
