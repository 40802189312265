import React, { useRef, useEffect, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import stickyfill from 'stickyfilljs';

import DrawerContent from '../DrawerContent';
import DrawerName from '../DrawerName';
import { SecondaryGhostButton } from '../buttons/SecondaryButton';
import { useSelectedDrawer } from '../../hooks/drawer';

import DrawerSelector from './DrawerSelector';
import styles from './styles.css';
import { operations } from '../../../redux';
import { injectIntl } from 'react-intl';
import messages from './messages';

const DrawersDashboard = injectIntl(({intl}) => {
  const selectedDrawer = useSelectedDrawer();
  const ref = useRef(null);
  useEffect(() => {
    stickyfill.add(ref.current);
  });

  const dispatch = useDispatch();
  const dispatchResetAllDrawers = useCallback(() => {
    dispatch(operations.cart.resetAllDrawers())
  }, [dispatch]);
  const dispatchResetSelectedDrawer = useCallback(() => {
    dispatch(operations.cart.resetDrawer(selectedDrawer))
  }, [dispatch, selectedDrawer]);

  return (
    <div className={styles.DrawersDashboard} ref={ref}>
      <DrawerSelector
        className={styles.DrawerSelector}
        selectedDrawer={selectedDrawer}
      />
      <DrawerName
        drawer={selectedDrawer}
        className={styles.DrawerName}
        showFreeSpaceText={true}
      />
      <DrawerContent position={selectedDrawer} contenteditable />
      <ul className={styles.actions}>
        <li>
          <SecondaryGhostButton buttonClassName={styles.actions__button} onClick={dispatchResetSelectedDrawer}>
            {intl.formatMessage(messages.resetDrawer)}
          </SecondaryGhostButton>
        </li>
        <li>
          <SecondaryGhostButton buttonClassName={styles.actions__button} onClick={dispatchResetAllDrawers}>
            {intl.formatMessage(messages.resetAll)}
          </SecondaryGhostButton>
        </li>
      </ul>
    </div>
  );
});

export default DrawersDashboard;
