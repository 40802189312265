import PropTypes from 'prop-types';
import { emptyCurrency } from './currency';

const limitToTwoDecimals = amount => Number(Math.round(amount + 'e2') + 'e-2');

const price = (amount) => {
  amount = limitToTwoDecimals(amount);
  return {
    amount
  };
};

export const nullPrice = price(0);

export const sumPrices = (...prices) =>
  price(prices.reduce((acc, cur) => { acc += cur.amount; return acc; }, 0));

export const priceFromGraphQlPrice = value => {
  if (value) {
    return price(value);
  }
  return nullPrice;
};

export const formatPrice = (intl, price, currency) => {
  if (currency === emptyCurrency) {
    return `${price.amount}${currency.symbol}`
  }
  return intl.formatNumber(price.amount, { style: 'currency', currency: currency.code })
}

export const propType = PropTypes.shape({ amount: PropTypes.number.isRequired });
