import { useQuery } from '@apollo/react-hooks';
import { useMemo } from 'react';
import { gql } from 'apollo-boost';
import useStoreViewCode from '../../app/hooks/useStoreViewCode';
import {useLocale} from '../../app/hooks/useLocale';
import config from '../../config';

const query = gql`
  {
    betms_available_industries
  }
`;

const useGetAvailableIndustriesQuery = () => {
  const storeViewCode = useStoreViewCode()
  const locale = useLocale();
  const { data, loading, error } = useQuery(query, { 
    context: {
      headers: {
        Store: storeViewCode
      },
      uri: config.cmsGraphQlServerUrl(locale)
    } });

  const modeledData = useMemo(
    () => {
      const items = (data && data.betms_available_industries) || [];
      return items;
    },
    [data]
  );

  return { data: modeledData, loading, error };
};

export default useGetAvailableIndustriesQuery;
