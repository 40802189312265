import React, { useCallback } from 'react';

import { useDrop } from 'react-dnd';

import { injectIntl } from 'react-intl';

import classNames from 'classnames';

import { useDispatch } from 'react-redux';

import { Form } from 'react-final-form';

import { DrawerCapacityIcon } from '../DrawerCapacity';
import config from '../../../config';
import { operations } from '../../../redux';
import { useTrolleySelectedData } from '../../hooks/cart';
import {
  useDispatchMoveFoamToDrawerIfItFits,
  useDispatchAddFoamToDrawerIfItFits
} from '../../hooks/drawer';
import { RadioArray } from '../../components/forms/Radio';

import messages from './DrawerSelector.messages';
import styles from './DrawerSelector.module.css';

const DrawerSelectorItem = ({ drawer }) => {
  const dispatchAddFoamToDrawer = useDispatchAddFoamToDrawerIfItFits();
  const dispatchMoveFoamToDrawer = useDispatchMoveFoamToDrawerIfItFits();
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [
      config.draggableItemTypes.foamFromList,
      config.draggableItemTypes.foamPreview
    ],
    drop: ({ type, foam }) =>
      type === config.draggableItemTypes.foamFromList
        ? dispatchAddFoamToDrawer(foam, drawer)
        : dispatchMoveFoamToDrawer(foam, drawer),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  return (
    <div
      className={classNames({
        [styles.DrawerSelectorItem]: true,
        [styles.isFoamDropping]: canDrop && isOver
      })}
      ref={drop}
    >
      <span className={styles.DrawerSelectorItem__number}>
        {drawer}
      </span>
      <DrawerCapacityIcon drawer={drawer} />
    </div>
  );
};

const DrawerSelector = injectIntl(({ className, intl, selectedDrawer }) => {
  const { drawers } = useTrolleySelectedData();
  const dispatch = useDispatch();
  const onRadioChange = useCallback(
    ({ value }) => dispatch(operations.foams.selectDrawer(value)),
    [dispatch]
  );
  const radios = [...Array(drawers).keys()].map(index => {
    const drawer = index + 1;
    return {
      label: <DrawerSelectorItem drawer={drawer} />,
      value: drawer
    };
  });
  return (
    <Form
      onSubmit={() => {}}
      initialValues={{ drawers: selectedDrawer }}
      render={({ handleSubmit }) =>
        <form
          onSubmit={handleSubmit}
          className={classNames(
            className,
            styles.drawerSelector,
            'u-scrollbars',
            'u-scrollbars--x'
          )}
        >
          <RadioArray
            cssmodule={styles}
            legend={intl.formatMessage(messages.selectDrawerToConfigure) + ':'}
            name="drawers"
            radios={radios}
            onChange={onRadioChange}
          />
        </form>}
    />
  );
});

export default DrawerSelector;
