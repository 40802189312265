import { defineMessages } from 'react-intl';

export default defineMessages({
  drawerHasNotEnoughFreeSpace: {
    id: 'drawerHasNotEnoughFreeSpace',
    defaultMessage: 'Drawer {drawerNumber} has not enough free space.'
  },
  addFoamToAnotherDrawer: {
    id: 'addFoamToAnotherDrawer',
    defaultMessage: 'You can add the foam to one of following drawers:'
  },
  moveFoamToAnotherDrawer: {
    id: 'moveFoamToAnotherDrawer',
    defaultMessage: 'You can move the foam to one of following drawers:'
  },
  yourTrolleyIsFull: {
    id: 'yourTrolleyIsFull',
    defaultMessage: 'Your trolley is full. You might want to make room in one of the drawers before adding more foams.'
  },
});
