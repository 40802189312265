const SET_INDUSTRIES_FILTER = 'SET_INDUSTRIES_FILTER';

const nextStateByType = {
  [SET_INDUSTRIES_FILTER]: (state, payload) => payload
};

const reducer = (state = 0, action) => {
  const nextState = nextStateByType[action.type] || (state => state);
  return nextState(state, action.payload);
};

const setSelectedIndustry = (industry) => ({
  type: SET_INDUSTRIES_FILTER,
  payload: industry * 1
});

export const operations = {
  setSelectedIndustry
};

export const selectors = {
  industriesFilter: state => state.foams.industriesFilter
}

export default reducer;