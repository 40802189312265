import * as React from 'react';

import classNames from 'classnames';

import { SnaPropTypes } from '../../propTypes';

import styles from './styles.css';

const FoamSize = ({ foam, className }) => {
  return foam && <span className={classNames(className, styles.FoamSize)}>{foam.size.label}</span>;
};

FoamSize.propTypes = {
  className: SnaPropTypes.className,
  foam: SnaPropTypes.foam.isRequired,
}

export default FoamSize;