const symbols = {
  EUR: '€'
};

const parseSymbol = code =>
  Object.prototype.hasOwnProperty.call(symbols, code) ? symbols[code] : code;

const currency = code => {
  const symbol = parseSymbol(code);

  return {
    code,
    symbol,
    toString: () => symbol
  };
};

export const currencyFromGraphQlCurrencyEnum = value => currency(value);

export const emptyCurrency = currency('-');