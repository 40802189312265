import { combineReducers } from 'redux';

import drawersPopUpReducer, * as drawersPopUp from './drawers-pop-up';
import industriesFilterReducer, * as industriesFilter from './industriesFilter';
import searchTextReducer, * as searchText from './searchText';
import selectedDrawerReducer, * as selectedDrawer from './selectedDrawer';
import sizesFilterReducer, * as sizesFilter from './sizesFilter';

const reducer = combineReducers({
  drawersPopUp: drawersPopUpReducer,
  sizesFilter: sizesFilterReducer,
  searchText: searchTextReducer,
  selectedDrawer: selectedDrawerReducer,
  industriesFilter: industriesFilterReducer
});

export const operations = {
  ...drawersPopUp.operations,
  ...sizesFilter.operations,
  ...searchText.operations,
  ...selectedDrawer.operations,
  ...industriesFilter.operations
}

export const selectors = {
  ...drawersPopUp.selectors,
  ...sizesFilter.selectors,
  ...searchText.selectors,
  ...selectedDrawer.selectors,
  ...industriesFilter.selectors
}

export default reducer;
