import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'name',
    defaultMessage: 'name'
  },
  productImage: {
    id: 'productImage',
    defaultMessage: 'product image'
  },
  price: {
    id: 'price',
    defaultMessage: 'price'
  },
  size: {
    id: 'size',
    defaultMessage: 'size'
  },
  sku: {
    id: 'sku',
    defaultMessage: 'sku'
  }
});
