import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { useInView } from 'react-intersection-observer';

import { SnaPropTypes } from '../../propTypes';
import useAppImageSizes from '../../hooks/useAppImageSizes';
import useImageUrlBase from '../../hooks/useImageUrlBase';

const shouldLoadImage = (lazy, isImageLoaded, inView) => {
  if (lazy) {
    return !isImageLoaded && inView;
  }
  return true;
};

const Image = ({
  alt,
  className,
  imageType,
  lazy = true,
  onError,
  src,
  title = ''
}) => {
  const imageSrc = useImageUrlBase(src);
  const size = useAppImageSizes(imageType);
  const [isImageLoaded, setImageLoaded] = useState(false);
  const [ref, inView] = useInView();
  useEffect(
    () => {
      shouldLoadImage(lazy, isImageLoaded, inView) && setImageLoaded(true);
    },
    [inView, isImageLoaded, lazy]
  );
  const imgAttrs = {
    alt,
    className,
    ref: lazy ? ref : null,
    src: isImageLoaded ? imageSrc : '',
    title,
    ...size
  };
  /* eslint-disable jsx-a11y/alt-text */
  return <img {...imgAttrs} onError={onError} />;
  /* eslint-enable jsx-a11y/alt-text */
};

Image.propTypes = {
  alt: PropTypes.string,
  className: SnaPropTypes.className,
  imageType: SnaPropTypes.imageType,
  lazy: PropTypes.bool,
  onError: PropTypes.func,
  src: PropTypes.string.isRequired,
  title: PropTypes.string
};

export { imageTypes } from '../../hooks/useAppImageSizes';

export default Image;
