import { useCallback, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import config from '../../config';
import { operations, selectors } from '../../redux';

import { useFoamsInDrawer, useFoamsByDrawer } from './cart';

const drawerCapacity = 3;

const computeFreeSpace = foams => {
  const freeSpace = foams.reduce(
    (acc, foam) => (foam ? acc - foam.size.value : acc),
    drawerCapacity
  );
  return {
    freeSpace,
    isFull: freeSpace === 0
  };
};

const doesFoamFitInDrawer = (foam, { freeSpace }) =>
  foam && freeSpace && freeSpace >= foam.size.value;

export const useDispatchAddFoamToDrawerIfItFits = () => {
  const dispatch = useDispatch();
  const drawersFreeSpace = useDrawersFreeSpace();
  return useCallback(
    (foam, drawer) =>
      doesFoamFitInDrawer(foam, drawersFreeSpace[drawer - 1])
        ? dispatch(operations.cart.addFoamToDrawer(foam, drawer))
        : dispatch(operations.foams.openDrawersPopUp(foam, drawer, config.drawersPopUpTypes.addTo)),
    [dispatch, drawersFreeSpace]
  );
};

export const useDispatchMoveFoamToDrawerIfItFits = () => {
  const dispatch = useDispatch();
  const drawersFreeSpace = useDrawersFreeSpace();
  return useCallback(
    (foam, drawer) =>
      doesFoamFitInDrawer(foam, drawersFreeSpace[drawer - 1])
        ? dispatch(operations.cart.moveFoamToDrawer(foam, drawer))
        : dispatch(operations.foams.openDrawersPopUp(foam, drawer, config.drawersPopUpTypes.moveTo)),
    [dispatch, drawersFreeSpace]
  );
};

export const useDrawerFreeSpace = drawer =>
  computeFreeSpace(useFoamsInDrawer(drawer));

const useDrawersFreeSpace = () => {
  const foamsByDrawer = useFoamsByDrawer();
  return useMemo(()=> foamsByDrawer.map((drawerFoams, index) => ({
    drawer: index + 1,
    ...computeFreeSpace(drawerFoams)
  })),[foamsByDrawer]);
};

export const useDrawersWhereFoamFits = foam => {
  const freeSpaces = useDrawersFreeSpace();
  return freeSpaces.filter(item => doesFoamFitInDrawer(foam, item));
};

export const useSelectedDrawer = () =>
  useSelector(selectors.foams.getSelectedDrawer);

export const useDoesFoamFitInTrolley = foam => {
  const freeSpaces = useDrawersFreeSpace();
  return freeSpaces.some(item => doesFoamFitInDrawer(foam, item));
}