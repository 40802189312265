import React from 'react';

import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { SnaPropTypes } from '../../../../propTypes';

import styles from './styles.css';

const AddToCartMini = ({
  className,
  clickHandler,
  cssModule = {},
  disabled,
  isInCart,
  messages,
  product,
}) => {
  const message = isInCart ? messages.remove : messages.add;
  return (
    <button
      className={classNames({
        ...(className && { [className]: true }),
        [cssModule.AddToCartMini]: true,
        [cssModule.isInCart]: isInCart,
        [cssModule.disabled]: disabled,
        [styles.AddToCartMini]: true,
        [styles.isInCart]: isInCart,
        [styles.disabled]: disabled
      })}
      onClick={clickHandler}
      disabled={disabled}
    >
      <span className={classNames({
        [cssModule.AddToCartMini__icon]: true,
        [styles.AddToCartMini__icon]: true
      })} />
      <span className="visually-hidden">
        <FormattedMessage {...message} values={{ productName: product.name }} />
      </span>
    </button>
  );
};

AddToCartMini.propTypes = {
  className: SnaPropTypes.className,
  clickHandler: PropTypes.func.isRequired,
  cssModule: SnaPropTypes.cssModule,
  disabled: PropTypes.bool,
  isInCart: PropTypes.bool,
  messages: PropTypes.shape({
    add: PropTypes.object.isRequired,
    remove: PropTypes.object.isRequired
  }).isRequired,
  product: SnaPropTypes.product.isRequired
};

export default AddToCartMini;
