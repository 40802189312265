import React from 'react';

import { injectIntl } from 'react-intl';

import classNames from 'classnames';

import PropTypes from 'prop-types';

import { DrawerCapacityText, DrawerCapacityIcon } from '../DrawerCapacity';
import { SnaPropTypes } from '../../propTypes';

import messages from './messages';
import styles from './styles.css';

const DrawerName = injectIntl(
  ({
    className,
    cssModule = {},
    drawer,
    intl,
    showFreeSpaceIcon = false,
    showFreeSpaceText = false
  }) => {
    return (
      <div
        className={classNames(
          className,
          cssModule.DrawerName,
          styles.DrawerName
        )}
      >
        <span
          className={classNames(
            cssModule.DrawerName__name,
            styles.DrawerName__name
          )}
        >
          {intl.formatMessage(messages.drawer)} {drawer}
        </span>
        {showFreeSpaceIcon &&
          <DrawerCapacityIcon
            className={classNames(
              cssModule.DrawerName__capacity,
              styles.DrawerName__capacity
            )}
            cssModule={cssModule}
            drawer={drawer}
          />}
        {showFreeSpaceText &&
          <DrawerCapacityText
            className={classNames(
              cssModule.DrawerName__capacity,
              styles.DrawerName__capacity
            )}
            cssModule={cssModule}
            drawer={drawer}
          />}
      </div>
    );
  }
);

DrawerName.propTypes = {
  className: SnaPropTypes.className,
  cssModule: SnaPropTypes.cssModule,
  drawer: SnaPropTypes.drawer,
  showFreeSpaceIcon: PropTypes.bool,
  showFreeSpaceText: PropTypes.bool
};

export default DrawerName;
