import React from 'react';

import { injectIntl } from 'react-intl';

import Price from '../Price';
import { useShouldShowPrice } from '../../hooks/useShouldShowPrice';

import styles from './Subtotal.module.css';
import { useShouldShowSubtotal, useSubtotal } from './hooks';
import messages from './messages';

const Subtotal = ({ intl, price, cssModule = styles, text = messages.subtotal }) => {
  const {data:shouldShowPrice} = useShouldShowPrice();
  const shouldShowSubtotal = useShouldShowSubtotal();
  const globalSubtotal = useSubtotal();
  price = price || globalSubtotal;
  return (
    shouldShowSubtotal && shouldShowPrice &&
    <p className={cssModule.subtotal}>
      <span className={cssModule.subtotal__label}>
        {intl.formatMessage(text)}:{' '}
      </span>
      <span className={cssModule.subtotal__value}>
        <Price value={price} />
      </span>
    </p>
  );
};

export default injectIntl(Subtotal);
