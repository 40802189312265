import { useDispatch } from 'react-redux';
import { operations } from '../../redux';
import { useCallback } from 'react';
import useStoreViewCode from './useStoreViewCode';
import { getConfig } from '../../graphql';
import { useApolloClient } from '@apollo/react-hooks';

const useDispatchSetConfig = () => {
  const dispatch = useDispatch();
  const storeViewCode = useStoreViewCode();
  const client = useApolloClient();
  return useCallback(({ configHash }) => {
    getConfig({ client, configHash, storeViewCode }).then(
      data =>
        data !== null &&
        dispatch(operations.cart.setCart({ cart: data.trolleyConfig, configHash, name: data.name }))
    );
  }, [client, dispatch, storeViewCode]);
}

export default useDispatchSetConfig;