import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import Image, { imageTypes } from '../Image';
import { SnaPropTypes } from '../../propTypes';

import styles from './styles.css';

const TrolleyPreview = ({ trolley, cssModule = {}, isHidden = false }) => {
  return (
    <figure
      className={classNames({
        [cssModule.TrolleyPreview]: true,
        [styles.TrolleyPreview]: true,
        [styles.isHidden]: isHidden
      })}
    >
      <Image
        alt={trolley.name}
        className={cssModule.TrolleyPreview__img}
        src={trolley.previewImage}
        imageType={imageTypes.TROLLEY_PREVIEW}
      />
    </figure>
  );
};

TrolleyPreview.propTypes = {
  trolley: SnaPropTypes.trolley.isRequired,
  cssModule: SnaPropTypes.cssModule,
  isHidden: PropTypes.bool
};

export default TrolleyPreview;
