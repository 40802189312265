import types from './types';
import arrayMove from '../../utils/array-move';

const initialState = {
  accessories: [],
  foams: [],
  hub: {
    model: null,
    color: null,
    drawers: null,
    chest: false
  }
};

const nextStateByType = {
  /*ACCESSORIES */
  [types.ADD_ACCESSORY_TO_CART]: (state, payload) => ({
    ...state,
    accessories: [...state.accessories, payload.id]
  }),
  [types.REMOVE_ACCESSORY_FROM_CART]: (state, payload) => ({
    ...state,
    accessories: state.accessories.filter(value => value !== payload.id)
  }),
  [types.SET_CART_ACCESSORIES]: (state, payload) => ({
    ...state,
    accessories: payload
  }),
  /* FOAMS */
  [types.SELECT_DRAWERS_LIMIT]: (state, payload) => ({
    ...state,
    foams: state.foams
      .concat([...Array(payload)].map(() => []))
      .slice(0, payload)
  }),
  [types.ADD_FOAM]: (state, payload) => ({
    ...state,
    foams: state.foams.map(
      (foamsInDrawer, index) =>
        index + 1 === payload.drawer
          ? foamsInDrawer.concat(payload.foam.id)
          : foamsInDrawer
    )
  }),
  [types.REMOVE_FOAM]: (state, payload) => ({
    ...state,
    foams: state.foams.map(drawer =>
      drawer.filter(foamId => foamId !== payload.id)
    )
  }),
  [types.REORDER_FOAMS]: (state, payload) => {
    const prev = state.foams.slice(0, payload.drawerPosition - 1);
    const curr = arrayMove(
      state.foams[payload.drawerPosition - 1],
      payload.oldIndex,
      payload.newIndex
    );
    const post = state.foams.slice(payload.drawerPosition);
    return { ...state, foams: [...prev, curr, ...post] };
  },
  [types.RESET_ALL_DRAWERS]: state => ({
    ...state,
    foams: state.foams.map(() => [])
  }),
  [types.RESET_DRAWER]: (state, drawer) => ({
    ...state,
    foams: state.foams.map((arr, index) => (drawer === index + 1 ? [] : arr))
  }),
  /* HUB */
  [types.SELECT_CHEST]: (state, payload) => ({
    ...state,
    hub: { ...state.hub, ...payload }
  }),
  [types.SELECT_DRAWERS]: (state, payload) => ({
    ...state,
    hub: { ...state.hub, ...payload }
  }),
  [types.SELECT_HUB]: (state, payload) =>
    state.hub.model !== payload.model
      ? { ...initialState, hub: { ...initialState.hub, ...payload } } // reset drawers
      : { ...state, hub: { ...state.hub, ...payload } }, // preserve drawers data if exists
  /* CART */
  [types.SET_CART]: (state, payload) => payload.cart
};

const reducer = (state = initialState, action) => {
  const nextState = nextStateByType[action.type] || (state => state);
  return nextState(state, action.payload);
};

export default reducer;
