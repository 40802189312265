import { defineMessages } from "react-intl";

export default defineMessages({
  getAQuote: {
    id: "getAQuote",
    defaultMessage: "Get a final quote"
  },
  total: {
    id: "total",
    defaultMessage: "Total"
  },
});